import axiosInstance, { axiosPost } from "../config/axiosInstance";
class BaseApi {
    constructor(resource) {
        this.resource = resource;
        this.url = `/${resource}`;
    }

    async get(id) {
        const response = await axiosInstance.get(`${this.url}/${id}`);
        return response.data;
    }

    async getAll() {
        const response = await axiosInstance.get(this.url);
        return response.data;
    }

    async create(data) {
        const response = await axiosPost.post(this.url, data);
        return response.data;
    }

    async update(id, data) {
        const response = await axiosPost.put(`${this.url}/${id}`, data);
        return response.data;
    }

    async delete(id) {
        const response = await axiosInstance.delete(`${this.url}/${id}`);
        return response.data;
    }
}

export default BaseApi;
