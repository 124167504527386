import { IMG_URL } from "../config/constants";

export const preparePoidsRowsAndColumns = (poids, baseCalcul, prix_ht_ambassadeur,prix_ttc) => {
    let poidsRows = [
        {
          key: 1,
          type_user: "Ambassadeur",
        },
      ];
      let columnsPoids = [{ title: "type user", dataIndex: "type_user" }];
      Object.keys(poids).forEach((key) => {
        columnsPoids.push({
          title: `${["pc", "hc"].includes(key) ? key + "........" : key} (${
            poids[key]
          })`,
          dataIndex: key,
        });

        poidsRows[0][key] =
          key === "remise"
            ? poids[key] + "%"
            : (
                (baseCalcul[key] === "HT" ? prix_ht_ambassadeur : prix_ttc) *
                (poids[key] / 100)
              ).toFixed(3) +
              "/" +
              baseCalcul[key];
      });

      return {columnsPoids, poidsRows}
}

export const prepareImagesProduitForFileUploader = (data) => {
    return data.map((image_produit) => {
        return {
          url: IMG_URL + image_produit.image,
          name: image_produit.image,
          status: "done",
        };
      })
}