import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { adminActions, adminStateSelector } from "../../store/reducers/adminReducer";

export default function PageTabButton({tab}){
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {tabs} = useSelector(adminStateSelector);
    const handleDeleteTab = (tabId) => {
        dispatch(adminActions.deleteTab(tabId));
        const index = tabs.findIndex(tab => tab.id === tabId);
        let previousTab = tabs[index-1];
        if(!previousTab){
            previousTab = tabs[index+1]
        }
        if (previousTab) {
            navigate(previousTab.link);
        } else {
            // navigate to default page
            navigate('/admin');
        }
    };
    return (
        <div className={`page-tab-button ${tab.active ? "page-tab-button-active" : ""}`}>
            <Link onClick={() => dispatch(adminActions.setActiveTab(tab))} to={tab.link}>
                {tab.icon}
                <p>{tab.label}</p>
            </Link>
            <p onClick={() => handleDeleteTab(tab.id)} className={"page-tab-button-close"}><CloseOutlined/></p>
        </div>
    )
}
