import { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, LogOut, ShoppingCart, User } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  userActions,
  userStateSelector,
} from "../../store/reducers/userReducer";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

export default function Header() {
  const { user } = useSelector(userStateSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [isFixed, setIsFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsFixed(scrollTop > 50);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div id={"header"}>
      <div id="topbar">
        <div id="topbar-logos">
          <img src="logo-cartajlife-blanc.png" alt="" height={50} />
          <img src="logo-cartajlife-blanc.png" alt="" height={50} />
          <img src="logo-cartajlife-blanc.png" alt="" height={50} />
          <img src="logo-cartajlife-blanc.png" alt="" height={50} />
        </div>
        <div id="topbar-ad">
          <p className="m-0">Espace marketing</p>
        </div>
        <div id="topbar-right">
          {user ? (
            <>
               <Button type="link" onClick={() => navigate('/admin')}>
                <User />
              </Button>
              <Button type="link">
                <ShoppingCart />
              </Button>
              <Button type="link" onClick={() => dispatch(userActions.logout())}>
                    <LogOut/>
              </Button>
            </>
          ) : (
            <Button
              onClick={() => dispatch(userActions.openLogin())}
              type="link"
            >
              Se connecter
            </Button>
          )}
        </div>
      </div>
      <div id="marketing-space">
        <ChevronLeft />
        <p>
          <span>Prenez soin de votre peau</span> avec nos{" "}
          <span>nettoyants visage</span> a petits prix
        </p>
        <ChevronRight />
      </div>
      <div id="header-menu" className={isFixed ? "fixed-header" : ""}>
        <ul className={"nav"}>
          <li className={"nav-item"}>
            <NavLink
              className={({ isActive }) => (isActive ? "active-link" : "")}
              to={"/"}
            >
              ACCEUIL
            </NavLink>
          </li>
          <li className={"nav-item"}>
            <NavLink
              className={({ isActive }) => (isActive ? "active-link" : "")}
              to={"/shop"}
            >
              ACILLA
            </NavLink>
          </li>
          <li className={"nav-item"}>HIA</li>
          <li className={"nav-item"}>AZLI</li>
          <li className={"nav-item"}>NOUVEAUTÉ</li>
          <li className={"nav-item"}>VENTE FLASH</li>
          <li className={"nav-item"}>CATALOGUES</li>
          <li className={"nav-item"}>BLOG</li>
        </ul>
      </div>
    </div>
  );
}
