import { createSlice } from "@reduxjs/toolkit";
import gammeThunk from "../thunks/gammeThunk";
import { IMG_URL, STATE_STATUS } from "../../config/constants";
const initialState = {
  rows: [],
  status: STATE_STATUS.idle,
  error: null,
  formMode: null,
  images: [],
  form: {
    id: undefined,
    label: "",
    image: "",
    sequence: 0,
    pc: 0,
    remise: 0,
    fidelite: 0,
    hc: 0,
    challenge: 0,
    grade: 0,
    prime_client: 0,
    prime_developpement: 0,
    prime_fidelite: 0,
    prime_leadership: 0,
    prime_excellence: 0,
  },
};
const slice = createSlice({
  name: "gamme",
  initialState,
  reducers: {
    setRows: (state, action) => {
      state.rows = action.payload;
    },
    setGammeState: (state, { payload }) => {
      const { field, value } = payload;
      state[field] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gammeThunk.getAll.fulfilled, (state, action) => {
        state.status = STATE_STATUS.idle;
        state.rows = action.payload;
      })
      .addCase(gammeThunk.create.fulfilled, (state, action) => {
        state.status = STATE_STATUS.idle;
        state.rows.push(action.payload);
        state.form = initialState.form;
      })
      .addCase(gammeThunk.update.fulfilled, (state, action) => {
        state.status = STATE_STATUS.idle;
        const index = state.rows.findIndex(
          (rows) => rows.id === action.payload.id
        );
        if (index !== -1) {
          state.rows[index] = action.payload;
        }
      })
      .addCase(gammeThunk.remove.fulfilled, (state, action) => {
        state.status = STATE_STATUS.idle;
        state.rows = state.rows.filter(
          (row) => row.id !== Number(action.payload)
        );
      })
      .addCase(gammeThunk.get.fulfilled, (state, action) => {
        state.status = STATE_STATUS.idle;
        const index = state.rows.findIndex(
          (rows) => rows.id === action.payload.id
        );
        if (index !== -1) {
          state.rows[index] = action.payload;
        }
      })
      .addCase(gammeThunk.prepareForUpdate.fulfilled, (state, { payload }) => {
        if (payload.image !== "") {
          state.images = [
            { url: IMG_URL + payload.image, name: payload.image },
          ];
        }
        state.formMode = "update";
      })
      .addMatcher(
        (action) =>
          action.type.endsWith("/pending") && action.type.startsWith("gammes"), // Match any action with a "pending" suffix
        (state) => {
          state.status = STATE_STATUS.loading;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, { payload }) => {
          state.status = STATE_STATUS.error;
          state.error = payload;
        }
      );
  },
});

export const { setrows, setGammeState } = slice.actions;
export const gammeStateSelector = (state) => state.gamme;
export default slice.reducer;
