import { Button, Typography } from "antd";
import { AlertOutlined, UserOutlined } from "@ant-design/icons";
const { Text, Paragraph } = Typography;
export default function TopBar({ Header, colorBgContainer }) {
  return (
    <div id="admin-header">
      <div
        className="row align-items-center"
        style={{
          background: "#001529",
          height: "100%",
          borderRadius: 8,
          boxShadow: "1px 1px 5px #eee",
        }}
      >
        <div className="col-12 col-md-8">
          <Button
            shape="circle"
            icon={
              <img
                height={"100%"}
                alt=""
                src="https://cartajlife.com/assets/img/grade/7.png"
              />
            }
          />
          <Text strong code>
            Abid Saifeddine
          </Text>
          <Text strong code copyable>
            www.acilla.com/1
          </Text>
        </div>

        <div className="col-12 col-md-4 d-flex justify-content-end">
          <Button type="primary" shape="circle" icon={<AlertOutlined />} />
          <Button
            className="ms-3"
            type="primary"
            shape="circle"
            icon={<UserOutlined />}
          />
        </div>
      </div>
    </div>
  );
}
