import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fraisTaxesStateSelector,
  setFraisTaxesForm,
  setFraisTaxesState,
} from "../../../store/reducers/fraisTaxesReducer";
import fraisTaxesThunk from "../../../store/thunks/fraisTaxesThunk";
import AppTable from "../../components/AppTable";
import { transporteurStateSelector } from "../../../store/reducers/transporteurReducer";
const typeOptions = [
  {
    value: "",
    label: "Tous",
  },
  {
    value: "frais",
    label: "Frais",
  },
  {
    value: "taxe",
    label: "Taxes",
  },
  {
    value: "frais de transport",
    label: "Frais de transport",
  },
  {
    value: "frais de retour transporteur",
    label: "Frais de retour transporteur",
  },
];
export default function GestionFraisEtTaxes() {
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },

    {
      title: "Label",
      dataIndex: "label",
    },

    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Année",
      dataIndex: "annee",
    },
    {
      title: "Valeur",
      dataIndex: "valeur",
    },
    {
      title: "Unité",
      dataIndex: "unite",
    },
    {
      title: "Client",
      dataIndex: "client_assujetti",
      render: (_,row) => <Checkbox checked={row.client_assujetti}/>
    },
    {
      title: "Ambassadeur",
      dataIndex: "ambassadeur_assujetti",
      render: (_,row) => <Checkbox checked={row.ambassadeur_assujetti}/>
    },
    {
      title: "Unité",
      dataIndex: "unite",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, row) => (
        <Space>
          <Button onClick={() => onUpdateClicked(row)} type="link">
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Confirmation de suppression"
            description="Vous êtes surs de vouloir supprimer cet entité?"
            onConfirm={() => deleteEntity(row.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [formRef] = Form.useForm();

  const dispatch = useDispatch();
  const { filterForm, rows, formMode, form } = useSelector(
    fraisTaxesStateSelector
  );
  const {rows: transporteurs} = useSelector(transporteurStateSelector)

  useEffect(() => {
    dispatch(fraisTaxesThunk.getAll(filterForm));
  }, [dispatch, filterForm]);
  const addEntity = () => {
    dispatch(setFraisTaxesState({ field: "formMode", value: "create" }));
  };

  const closeForm = useCallback(() => {
    dispatch(setFraisTaxesState({ field: "formMode", value: null }));
    formRef.setFieldsValue(form);
    formRef.setFieldValue("id", null);
    formRef.setFieldValue("type", "");
    formRef.setFieldValue("id_transporteur", "");
  }, [dispatch, form, formRef]);

  useEffect(() => {
    if (formMode === null) closeForm();
  }, [formMode, closeForm]);

  const submit = (values) => {
    if (values.id)
      dispatch(fraisTaxesThunk.update({ id: values.id, data: values }));
    else dispatch(fraisTaxesThunk.create(values));
  };

  const submitFilters = (values) => {
    dispatch(
      setFraisTaxesState({
        field: "filterForm",
        value: { ...values, annee: values.annee?.format("YYYY") ?? undefined },
      })
    );
  };

  const onUpdateClicked = (row) => {
    dispatch(setFraisTaxesState({ field: "formMode", value: "update" }));
    formRef.setFieldsValue(row);
    dispatch(setFraisTaxesForm({field: "type", value: row.type}))
  };

  const deleteEntity = (id) => {
    dispatch(fraisTaxesThunk.remove(id));
  };
  useEffect(() => {
    console.log(form);
  }, [form]);
  return (
    <div>
      {/* Header */}
      <div className="d-flex align-items-center justify-content-between my-2">
        <Typography.Title level={3}>
          Gestion des frais et des taxes
        </Typography.Title>

        <Button
          size="small"
          onClick={addEntity}
          type="primary"
          icon={<PlusOutlined />}
        >
          Ajouter
        </Button>
      </div>

      <Form onFinish={submitFilters}>
        <Row gutter={16}>
          <Col xs={12} md={4}>
            <Form.Item name={"annee"}>
              <DatePicker placeholder="Recherche avec année" picker="year" />
            </Form.Item>
          </Col>
          <Col xs={24} md={4}>
            <Form.Item name="type">
              <Select placeholder="Recherche avec type">
                {typeOptions.map((to) => (
                  <Select.Option key={to.value} value={to.value}>
                    {to.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item name="label">
              <Input placeholder="Recherche avec label" />
            </Form.Item>
          </Col>
          <Col xs={24} md={2}>
            <Form.Item>
              <Button htmlType="submit" icon={<SearchOutlined />}>
                Filtrer
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {/* List */}
      <AppTable columns={columns} dataSource={rows} />

      <Drawer
        title={
          formMode === "create"
            ? "Ajouter une nouvelle entrée"
            : "Modifier une entrée"
        }
        open={formMode !== null}
        width={800}
        onClose={closeForm}
      >
        <Form
          onFinish={submit}
          initialValues={form}
          layout="vertical"
          form={formRef}
        >
          <Form.Item name={"id"} hidden={true}>
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col xs={12} md={9}>
              <Form.Item name="label" label="Libellé" required>
                <Input placeholder="Libéllé" required/>
              </Form.Item>
            </Col>
            <Col xs={12} md={6}>
              <Form.Item
                name="type"
                label="Type"
                rules={[
                  { required: true, message: "Veuillez choisir le type" },
                ]}
              >
                <Select
                  onChange={(value) =>
                    {
                      formRef.setFieldValue(
                        "unite",
                        value.includes("frais") ? "DT" : "%"
                      )
                      dispatch(setFraisTaxesForm({field: "type", value}))
                    }
                  }
                  label="Type"
                  name="type"
                >
                  {typeOptions
                    .filter((to) => to.value !== "")
                    .map((to) => (
                      <Select.Option key={to.value} value={to.value}>
                        {to.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {form.type?.includes("frais") && (
              <Col xs={24} md={6}>
               <Form.Item label="Transporteur" name="id_transporteur">
               <Select>
                  {
                    transporteurs.map(t => (
                      <Select.Option key={t.id} value={t.id}>{t.label}</Select.Option>
                    ))
                  }
                </Select>
               </Form.Item>
              </Col>
            )}
            <Col xs={12} md={3}>
              <Form.Item name="annee" label="Année" required>
                <Input required placeholder="2022, 2023, etc..." />
              </Form.Item>
            </Col>
            <Col xs={12} md={3}>
              <Form.Item name={"valeur"} label="Valeur" required>
                <Input required placeholder="19,7, etc..." />
              </Form.Item>
            </Col>
            <Col xs={12} md={3}>
              <Form.Item name="unite" label="Unité" required>
                <Select label="Unité" name="unite">
                  <Select.Option value="DT">DT</Select.Option>
                  <Select.Option value="%">%</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          {
            form?.type.includes('taxe') &&
            <>
              <Col xs={12} md={3}>
            <Form.Item label="Client" name="client_assujetti" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col xs={12} md={3}>
            <Form.Item label="Ambassadeur" name="ambassadeur_assujetti" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            </>
          }
            <Col span={24}>
              <Space className="justify-content-end">
                <Button onClick={closeForm} icon={<CloseOutlined />}>
                  Annuler
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Enregistrer
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
