import { createSlice } from "@reduxjs/toolkit";
import userThunk from "../thunks/userThunk";
import { STATE_STATUS } from "../../config/constants";

const initialState = {
  status: STATE_STATUS.idle,
  user: null,
  error: null,
  showLogin: false,
};

const slice = createSlice({
  initialState,
  name: "user",
  reducers: {
    logout: (state, _) => {
      state.user = null;
      localStorage.removeItem("token");
    },
    openLogin: (state, _) => {
      state.showLogin = true;
    },
    closeLogin: (state, _) => {
      state.showLogin = false;
    },
    setStatus: (state, payload) => {
      state.status = payload;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userThunk.login.fulfilled, (state, action) => {
        if (!action.payload.error) {
          state.user = action.payload.user;
          state.status = STATE_STATUS.idle;
          localStorage.setItem("token", action.payload.token);
          state.showLogin = false
        }else {
          state.error = action.payload.error.message;
        }
      })
      .addCase(userThunk.login.rejected, (state, _) => {
        state.status = STATE_STATUS.error;
        state.user = null;
        state.error = "Erreur d'authentification!";
      })
      .addCase(userThunk.login.pending, (state, _) => {
        state.status = STATE_STATUS.loading;
      });
  },
});

export default slice.reducer;
export const { logout, openLogin, closeLogin, setStatus } = slice.actions;
export const userActions = { logout, openLogin, closeLogin, setStatus };
export const userStateSelector = (state) => state.user;
