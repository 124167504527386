import { Layout, theme } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import categorieThunk from "../store/thunks/categorieThunk";
import gammeThunk from "../store/thunks/gammeThunk";
import produitThunk from "../store/thunks/produitThunk";
import SousCategorieThunk from "../store/thunks/sousCategorieThunk";
import SideMenu from "./layout/Menu";
import Main from "./layout/content";
import TopBar from "./layout/topBar";
import { gestionProduitsStateSelector } from "../store/reducers/gestionProduitsReducer";
import transporteurThunk from "../store/thunks/transporteurThunk";
const {Header, Content: content, Sider} = Layout
export default function Admin(){
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const dispatch = useDispatch();
    const { activeTab } = useSelector(gestionProduitsStateSelector);


    useEffect(() => {
      dispatch(gammeThunk.getAll());
      dispatch(categorieThunk.getAll());
      dispatch(SousCategorieThunk.getAll());
      dispatch(produitThunk.getAll())
      dispatch(transporteurThunk.getAll());
    }, [dispatch,activeTab])
    return (
            <Layout style={{height: "100vh"}}>
                <SideMenu Sider={Sider} colorBgContainer={colorBgContainer}/>
            <Layout>
                <TopBar Header={Header}colorBgContainer={colorBgContainer}/>
                <Main Content={content} colorBgContainer={colorBgContainer}/>
            </Layout>
        </Layout>
    )
}
