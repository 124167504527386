import { createSlice } from "@reduxjs/toolkit";
import venteThunk from "../thunks/venteThunk";
import { STATE_STATUS } from "../../config/constants";
import { formatProduitVente } from "../../utils/admin_module_vente_utils";
import { SQLDateTodayjs } from "../../utils/dateUtils";

const initialState = {
  rows: [],
  status: STATE_STATUS.idle,
  error: null,
  form: {
    label: "",
    date_debut: null,
    date_fin: null,
    priorite: 0,
    max_produits_cadeaux: 0,
  },
  venteProduits: [],
  venteProduitsCadeaux: [],
  formMode: null,
};

const slice = createSlice({
  initialState,
  name: "vente",
  reducers: {
    setVenteState: (state, { payload }) => {
      const { field, value } = payload;
      state[field] = value;
    },
    setVenteForm: (state, { payload }) => {
      const { field, value } = payload;
      state.form[field] = value;
    },
    resetVente: (state) => {
      state.form = initialState.form;
      state.venteProduits = [];
      state.venteProduitsCadeaux = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(venteThunk.getAll.fulfilled, (state, { payload }) => {
        state.rows = payload;
        state.status = STATE_STATUS.idle;
      })

      .addCase(venteThunk.createVente.fulfilled, (state, { payload }) => {
        // close drawer
        state.formMode = null;
        // refrech liste vente
        state.rows.push(payload.vente);
        // set status to idle
        state.status = STATE_STATUS.idle;
      })

      .addCase(venteThunk.deleteVente.fulfilled, (state, { payload }) => {
        state.status = STATE_STATUS.idle;
        state.rows = state.rows.filter(
          (rows) => rows.id !== Number(payload.id)
        );
      })
      .addCase(venteThunk.getVente.fulfilled, (state, { payload }) => {
        const { vente, produits, cadeaux } = payload;
        state.status = STATE_STATUS.idle;
        state.form = vente;
        state.venteProduits = produits.map((p, i) =>
          formatProduitVente({ ...p, index: i + 1 }, produits, "produit", true)
        );
        state.venteProduitsCadeaux = cadeaux.map((p, i) =>
          formatProduitVente({ ...p, index: i + 1 }, cadeaux, "cadeau", true)
        );
        state.formMode = "update";
      })
      .addCase(venteThunk.updateVente.fulfilled, (state, { payload }) => {
        const index = state.rows.findIndex((r) => r.id === payload.id);
        if (index !== -1) {
          state.rows[index] = payload;
          state.status = STATE_STATUS.idle;
          state.formMode = null;
        }
      })

      .addMatcher(
        (action) => action.type.endsWith("/pending"), // Match any action with a "pending" suffix
        (state) => {
          state.status = STATE_STATUS.loading;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, {payload}) => {
          state.status = STATE_STATUS.error;
          state.error = payload;
        }
      );
  },
});

export default slice.reducer;
export const venteStateSelector = (state) => state.vente;
export const { setVenteState, setVenteForm, resetVente } = slice.actions;
