import genericThunk from "./baseThunk";
import VenteApi from "../../api/venteApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

const Api = new VenteApi();
const createVente = createAsyncThunk(`vente/create`, async (data, thunkApi) => {
    try {
      return await Api.createVente(data);
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.error.sqlMessage)
    }
  });
const deleteVente = createAsyncThunk(`vente/delete`, async id => {
    return await Api.deleteVente(id);
  });
const getVente = createAsyncThunk(`vente/get`, async id => {
    return await Api.getVente(id);
  });
const updateVente = createAsyncThunk(`vente/update`, async ({id,data}) => {
    return await Api.updateVente(id, data);
  });
  const extraReducers = { createVente,deleteVente,getVente,updateVente };
const venteThunk = genericThunk(Api,extraReducers)
export default venteThunk
