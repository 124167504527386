import "animate.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Admin from "./admin";
import Dashboard from "./admin/modules/dashboard";
import GestionProduits from "./admin/modules/gestionStock/gestionProduits";
import GestionCategories from "./admin/modules/gestionStock/gestionProduits/categories";
import GestionGammes from "./admin/modules/gestionStock/gestionProduits/gammes";
import GestionProduitsModule from "./admin/modules/gestionStock/gestionProduits/produits";
import GestionSousCategories from "./admin/modules/gestionStock/gestionProduits/sousCategories";
import GestionVentes from "./admin/modules/gestionStock/gestionVentes";
import GestionFraisEtTaxes from "./admin/modules/parametres/gestionFraisEtTaxes";
import Client from "./client";
import Landing from "./client/components/landing";
import Shop from "./client/components/shop";
import GestionTransporteurs from "./admin/modules/parametres/gestionTransporteurs";
import GestionPoids from "./admin/modules/parametres/gestionPoids";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Client />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/shop",
        element: <Shop />,
      },
    ],
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "*",
        element: <h2 style={{fontSize: "2rem", textAlign: "center", marginTop: 48}}>En cours de développement</h2>
      },
      {
        path: "/admin",
        element: <Dashboard />,
      },
      {
        path: "/admin/gestion-produits",
        element: <GestionProduits />,
        children: [
          {
            path: "/admin/gestion-produits/gammes",
            element: <GestionGammes />,
          },
          {
            path: "/admin/gestion-produits/categories",
            element: <GestionCategories />,
          },
          {
            path: "/admin/gestion-produits/sub-categories",
            element: <GestionSousCategories />,
          },
          {
            path: "/admin/gestion-produits/products",
            element: <GestionProduitsModule />,
          },
        ],
      },
      {
        path: "/admin/ventes",
        element: <GestionVentes />,
      },
      {
        path: "/admin/parametres",
        children: [
          {
            path: "/admin/parametres/frais-taxes",
            element: <GestionFraisEtTaxes />,
          },
          {
            path: "/admin/parametres/transporteurs",
            element: <GestionTransporteurs />,
          },
          {
            path: "/admin/parametres/poids",
            element: <GestionPoids />,
          },
        ],
      },
    ],
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
