// api/BaseApi.js
import axiosInstance, { axiosPost } from "../config/axiosInstance";
import BaseApi from "./baseApi";

export default class VenteApi extends BaseApi {
  constructor() {
    super("ventes");
  }
  async createVente(data) {
    return await axiosPost
      .post(`/${this.resource}/create`, data)
      .then((res) => res.data);
  }
  async deleteVente(id) {
    return await axiosInstance
      .delete(`/${this.resource}/delete/${id}`)
      .then((res) => res.data);
  }
  async getVente(id) {
    return await axiosInstance
      .get(`/${this.resource}/get/${id}`)
      .then((res) => res.data);
  }
  async updateVente(id, data) {
    return await axiosPost
      .put(`/${this.resource}/update/${id}`, data)
      .then((res) => res.data);
  }
}
