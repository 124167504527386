export const formatProduitVente = (produit, produits, type, update = false) => {
    const index = Math.max(...produits.map((item) => item?.index ?? 0), 0) + 1;
      return {
          ...produit,
          index: update ? produit.index : index,
          type,
          promotion: update ? produit.promotion : 0,
          prix_ht_initial: update ? produit.prix_ht_initial : produit.prix_ht,
          id_produit: update ? produit.id_produit : produit.id,
          client: update ? produit.client : 0,
          ambassadeur: update ? produit.ambassadeur : 1
        }
}