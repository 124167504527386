import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setTransporteurState,
  transporteurStateSelector,
} from "../../../store/reducers/transporteurReducer";
import transporteurThunk from "../../../store/thunks/transporteurThunk";
import AppTable from "../../components/AppTable";
import { IMG_URL } from "../../../config/constants";
import FilesUploader from "../../components/filesUploader";
export default function GestionFraisEtTaxes() {
  const [images, setImages] = useState([]);
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (_, row) =>
        row.image !== "" ? (
          <Avatar src={`${IMG_URL}${row.image}`} alt="" />
        ) : null,
    },
    {
      title: "Label",
      dataIndex: "label",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, row) => (
        <Space>
          <Button onClick={() => onUpdateClicked(row)} type="link">
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Confirmation de suppression"
            description="Vous êtes surs de vouloir supprimer cet entité?"
            onConfirm={() => deleteEntity(row.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [formRef] = Form.useForm();

  const dispatch = useDispatch();
  const { rows, formMode, form } = useSelector(transporteurStateSelector);

  const addEntity = () => {
    dispatch(setTransporteurState({ field: "formMode", value: "create" }));
  };

  const closeForm = useCallback(() => {
    dispatch(setTransporteurState({ field: "formMode", value: null }));
    formRef.setFieldsValue(form);
    formRef.setFieldValue("id", null);
  }, [dispatch, form, formRef]);

  useEffect(() => {
    if (formMode === null) closeForm();
  }, [formMode, closeForm]);

  const submit = (values) => {
    if (values.id)
      dispatch(transporteurThunk.update({ id: values.id, data: values }));
    else dispatch(transporteurThunk.create(values));
  };

  const onUpdateClicked = (row) => {
    dispatch(setTransporteurState({ field: "formMode", value: "update" }));
    formRef.setFieldsValue(row);
    if (row.image !== "")
      setImages([{ url: IMG_URL + row.image, name: row.image }]);
  };

  const deleteEntity = (id) => {
    dispatch(transporteurThunk.remove(id));
  };
  return (
    <div>
      {/* Header */}
      <div className="d-flex align-items-center justify-content-between my-2">
        <Typography.Title level={3}>Gestion des transporteurs</Typography.Title>

        <Button
          size="small"
          onClick={addEntity}
          type="primary"
          icon={<PlusOutlined />}
        >
          Ajouter
        </Button>
      </div>

      {/* List */}
      <AppTable columns={columns} dataSource={rows} />

      <Drawer
        title={
          formMode === "create"
            ? "Ajouter une nouvelle entrée"
            : "Modifier une entrée"
        }
        open={formMode !== null}
        width={300}
        onClose={closeForm}
      >
        <Form
          onFinish={submit}
          initialValues={form}
          layout="vertical"
          form={formRef}
        >
          <Form.Item name={"id"} hidden={true}>
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item name="label" label="Libellé" required>
                <Input placeholder="Libéllé" required />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="image" hidden={true}>
                <Input />
              </Form.Item>
              {formMode !== null && (
                <FilesUploader
                  initalFiles={images}
                  onServerUploaded={(files) =>
                    formRef.setFieldValue("image", files[0]?.name ?? "")
                  }
                  nbFiles={1}
                />
              )}
            </Col>
            <Col span={24}>
              <Space className="justify-content-end">
                <Button onClick={closeForm} icon={<CloseOutlined />}>
                  Annuler
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Enregistrer
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
