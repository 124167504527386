import { Outlet } from "react-router-dom";
import Header from "./components/header";

export default function Client() {
  return (
    <div id={"client"}>
      <Header />
      <Outlet />
    </div>
  );
}
