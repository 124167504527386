import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { categorieStateSelector } from "../../../../store/reducers/categorieReducer";
import { sousCategorieStateSelector } from "../../../../store/reducers/sousCategoriesReducer";
import sousCategorieThunk from "../../../../store/thunks/sousCategorieThunk";
import AppTable from "../../../components/AppTable";
import { IMG_URL } from "../../../../config/constants";
import FilesUploader from "../../../components/filesUploader";
export default function GestionSousCategories() {
  const dispatch = useDispatch();
  const { rows, createStatus, error, form, deleteStatus } = useSelector(
    sousCategorieStateSelector
  );
  const { rows: parents } = useSelector(categorieStateSelector);
  const [formRef] = Form.useForm();
  const [parentValue, setParentValue] = useState("");
  const [images, setImages] = useState([]);
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (_, row) =>
        row.image !== "" ? (
          <Avatar src={`${IMG_URL}${row.image}`} alt="" />
        ) : null,
    },
    {
      title: "libellé",
      dataIndex: "label",
    },
    {
      title: "Catégorie",
      dataIndex: "label_categorie",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, row) => (
        <Space>
          <Button
            onClick={() => onUpdateClicked(row)}
            type="link"
            size="small"
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Confirmation de suppression"
            description="Vous êtes surs de vouloir supprimer cet entité?"
            onConfirm={() => deleteEntity(row.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
      width: 70,
    },
  ];
  const [formMode, setFormMode] = useState(null);
  const onClose = () => {
    setFormMode(null);
    clearForm();
  };

  const clearForm = () => {
    formRef.setFieldsValue(form);
  };

  const submitForm = (values) => {
    if (values.id === undefined) dispatch(sousCategorieThunk.create(values));
    else dispatch(sousCategorieThunk.update({ id: values.id, data: values }));
    onClose();
  };



  const onUpdateClicked = (row) => {
    setFormMode("update");
    formRef.setFieldsValue(row);
    setParentValue(row.id_categorie);
    if (row.image !== "")
      setImages([{ url: IMG_URL + row.image, name: row.image }]);
  };

  const deleteEntity = (id) => {
    dispatch(sousCategorieThunk.remove(id));
  };
  useEffect(() => {
    if (deleteStatus === "succeeded")
      message.success("Entité supprimée avec succès!");
  }, [deleteStatus]);

  const onParentSelected = (id) => {
    setParentValue(id);
    let p = parents.find((p) => p.id === id);
    if (p !== undefined) {
      formRef.setFieldsValue({
        ...formRef.getFieldsValue,
        id_categorie: p.id,
      });
    } else {
      formRef.setFieldsValue({
        ...formRef.getFieldsValue,
        id_categorie: null,
      });
    }
  };
  useEffect(() => {
    if (formMode !== "update") setParentValue("");
  }, [formMode]);
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between my-2">
        <Typography.Title className="m-0" level={4}>
          Gestion des SousCategories
        </Typography.Title>
        <Tooltip title="Ajouter sous categorie">
          <Button
            onClick={() => setFormMode("create")}
            type="primary"
            size={"small"}
            icon={<PlusOutlined />}
          >
            Ajouter
          </Button>
        </Tooltip>
      </div>
      <AppTable size={"small"} bordered columns={columns} dataSource={rows} />
      <Drawer
        title={
          formMode === "create"
            ? "Créer une nouvelle sous categorie"
            : "Modifier la sous categorie"
        }
        width={600}
        onClose={onClose}
        open={formMode !== null}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          initialValues={form}
          form={formRef}
          onFinish={submitForm}
          layout="vertical"
        >
          <Row gutter={16}>
            <FormItem name={"id"} hidden={true}>
              <Input />
            </FormItem>
            <FormItem name={"id_categorie"} hidden={true}>
              <Input />
            </FormItem>
            <Col span={12}>
              <Form.Item
                name="label"
                label="Libéllé"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le nom de la sous categorie",
                  },
                ]}
              >
                <Input placeholder="Nom de la categorie" id="input-label" />
              </Form.Item>
            </Col>
            <Col span={12} className="mb-2">
              <label className="pb-2">Séléctionner une catégorie</label>
              <Select
                value={parentValue}
                style={{ width: "100%" }}
                onChange={onParentSelected}
              >
                <Select.Option value={""}></Select.Option>
                {parents.map((p) => (
                  <Select.Option value={p.id} key={p.id}>
                    {p.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={24}>
              <Form.Item name="image" hidden={true}>
                <Input />
              </Form.Item>
              {formMode !== null && (
                <FilesUploader
                  initalFiles={images}
                  onServerUploaded={(files) =>
                    formRef.setFieldValue("image", files[0]?.name ?? "")
                  }
                  nbFiles={1}
                />
              )}
            </Col>
          </Row>

          <Space align="center" className="justify-content-end">
            <Button onClick={onClose} icon={<CloseOutlined />}>
              Annuler
            </Button>
            <Button
              loading={createStatus === "loading"}
              htmlType={"submit"}
              type="primary"
              icon={<SaveOutlined />}
            >
              Enregistrer
            </Button>
          </Space>
          <p>{error}</p>
        </Form>
      </Drawer>
    </div>
  );
}
