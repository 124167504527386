import {Eye, ShoppingCart} from "react-feather";

export default function Product(){
    return (
        <div className={"product"}>
            <div className="product-discount"><span>45%</span></div>
            <div className="product-top">
                <img src="assets/img/products/2.png" alt=""/>
                <div className="product-overlay"/>
                <div className="product-buttons">
                    <button>
                        <ShoppingCart/>
                    </button>
                    <button>
                        <Eye/>
                    </button>
                </div>
            </div>
            <div className="product-details">
                <p>EAU DE PARFUM POUR LUI ESSOLTANE</p>
                <div className={"product-prices"}>
                    <p className={"product-promo"}><span>30 DT</span></p>
                    <p className={"product-price"}><span>64.9 DT</span></p>
                </div>
                <p className={"product-category"}><span>PARFUM</span> <span className={"circle-filled ms-1"}/></p>
            </div>


        </div>
    )
}
