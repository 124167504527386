import { blue, red } from "@ant-design/colors";
import {
  AppstoreFilled,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  GiftFilled,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Input,
  Popconfirm,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProduitsApi from "../../../../api/produitApi";
import { STATE_STATUS } from "../../../../config/constants";
import { categorieStateSelector } from "../../../../store/reducers/categorieReducer";
import { sousCategorieStateSelector } from "../../../../store/reducers/sousCategoriesReducer";
import {
  resetVente,
  setVenteForm,
  setVenteState,
  venteStateSelector,
} from "../../../../store/reducers/venteReducer";
import venteThunk from "../../../../store/thunks/venteThunk";
import { formatProduitVente } from "../../../../utils/admin_module_vente_utils";
import {
  SQLDateTodayjs,
  dateTimeToFr,
  dayjsToSQLDate,
} from "../../../../utils/dateUtils";
import AppTable from "../../../components/AppTable";
import VerticalSpace from "../../../components/VerticalSpace";
import ErrorModal from "../../../components/errorModal";
import PageTitle from "../../../components/pageTitle";
import TableCellInput from "../../../components/tableCellInput";
const produitsApi = new ProduitsApi();
function GestionVentes() {
  // vente state
  const {
    error,
    rows,
    form,
    venteProduits,
    venteProduitsCadeaux,
    status,
    formMode,
  } = useSelector(venteStateSelector);

  // categories
  const { rows: categories } = useSelector(categorieStateSelector);
  // sous catégories
  const { rows: sousCategories } = useSelector(sousCategorieStateSelector);
  // filter form state
  const [filterForm, setFilterForm] = useState({
    id_categorie: "",
    id_sous_categorie: "",
    reference: "",
  });
  // all references produits
  const [referencesProduits, setReferencesProduits] = useState([]);
  // filtered produits
  const [filteredProduits, setFilteredProduits] = useState([]);

  // selected produit state
  const [selectedProduit, setSelectedProduit] = useState(null);
  // handle filter form changes
  const handleChangeFilterForm = (field, value) => {
    setFilterForm({ ...filterForm, [field]: value });
  };
  // onchange filter form ==> get produits
  useEffect(() => {
    produitsApi
      .getFilteredProduits(filterForm)
      .then((res) => setFilteredProduits(res))
      .catch((error) => console.log(error));
    produitsApi
      .getAllReferences()
      .then((res) => setReferencesProduits(res))
      .catch((error) => console.log(error));
  }, [filterForm]);
  // dispatch instance
  const dispatch = useDispatch();
  // get all ventes when components is loaded
  useEffect(() => {
    dispatch(venteThunk.getAll());
  }, [dispatch]);

  // vente produits settter
  const setVenteProduits = (value) => {
    dispatch(setVenteState({ field: "venteProduits", value }));
  };
  // vente produits cadeaux settter
  const setVenteProduitsCadeaux = (value) => {
    dispatch(setVenteState({ field: "venteProduitsCadeaux", value }));
  };

  // push produits / produit cadeau into produits vente array
  /**
   *
   * @param {*} reference
   * @param {*} type
   */
  const addProduitToVente = async (reference, type) => {
    const produit = await produitsApi.getProduitPourVente(reference);
    if (type === "produit") {
      setVenteProduits([
        ...venteProduits,
        formatProduitVente(produit, venteProduits, "produit"),
      ]);
    } else {
      setVenteProduitsCadeaux([
        ...venteProduitsCadeaux,
        formatProduitVente(produit, venteProduitsCadeaux, "cadeau"),
      ]);
    }
  };

  // remove produit from vente ou cadeau
  const removeProduitFromVente = (index, type) => {
    if (type === "produit") {
      setVenteProduits(venteProduits.filter((p) => p.index !== index));
    } else {
      setVenteProduitsCadeaux(
        venteProduitsCadeaux.filter((p) => p.index !== index)
      );
    }
  };

  const handleVenteRowValueChanged = (
    rowIndex,
    field,
    value,
    type,
    prix_ht_initial
  ) => {
    const index = rowIndex - 1;
    let promotionValue = null,
      prixHtValue = null;
    if (field === "promotion") {
      const promotion = Number(value);
      if (promotion > 0)
        prixHtValue = Number(
          (prix_ht_initial * (1 - promotion / 100)).toFixed(3)
        );
      else {
        promotionValue = 0;
        prixHtValue = prix_ht_initial;
      }
    }
    if (field === "prix_ht") {
      const prix_ht = Number(value);
      if (prix_ht < prix_ht_initial) {
        const diff = prix_ht_initial - prix_ht;
        promotionValue = Number(((diff / prix_ht_initial) * 100).toFixed(3));
      } else {
        promotionValue = 0;
      }
    }
    if (type === "produit") {
      let produits = [...venteProduits];
      let produitWU = { ...produits[index] };
      if (promotionValue !== null) produitWU["promotion"] = promotionValue;
      if (prixHtValue !== null) produitWU["prix_ht"] = prixHtValue;
      produitWU[field] = value;
      produits[index] = produitWU;
      setVenteProduits(produits);
    } else {
      let produits = [...venteProduitsCadeaux];
      let produitWU = { ...produits[index] };
      if (promotionValue !== null) produitWU["promotion"] = promotionValue;
      if (prixHtValue !== null) produitWU["prix_ht"] = prixHtValue;
      produitWU[field] = value;
      produits[index] = produitWU;
      setVenteProduitsCadeaux(produits);
    }
  };

  const setFormMode = (value) => {
    dispatch(setVenteState({ field: "formMode", value }));
  };

  const deleteEntity = (id) => {
    dispatch(venteThunk.deleteVente(id));
  };

  useEffect(() => {
    if (formMode === null) {
      dispatch(resetVente());
    }
  }, [formMode, dispatch]);

  // columns vente
  const columns = [
    {
      title: "Label",
      dataIndex: "label",
    },
    {
      title: "Date début",
      dataIndex: "date_debut",
      render: (_, row) => dateTimeToFr(row.date_debut),
    },
    {
      title: "Date fin",
      dataIndex: "date_fin",
      render: (_, row) => dateTimeToFr(row.date_fin),
    },
    {
      title: "Nb_produits",
      dataIndex: "produit_count",
      render: (_, row) => row.produit_count ?? 0,
    },
    {
      title: "Nb_cadeaux",
      dataIndex: "cadeau_count",
      render: (_, row) => row.cadeau_count ?? 0,
    },
    {
      title: "Priorité",
      dataIndex: "priorite",
    },
    {
      title: "Max cadeaux",
      dataIndex: "max_produits_cadeaux",
    },
    {
      title: "actions",
      render: (_, row) => (
        <Space>
          <Button
            type="link"
            size="small"
            onClick={() => dispatch(venteThunk.getVente(row.id))}
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Confirmation de suppression"
            description="Vous êtes surs de vouloir supprimer cet entité?"
            onConfirm={() => deleteEntity(row.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
      fixed: "right",
      width: 70,
      dataIndex: "actions",
    },
  ];

  // columns produit
  const columnsProduit = [
    {
      title: "reference",
      dataIndex: "reference",
      fixed: "left",
    },
    {
      title: "label",
      dataIndex: "label",
      fixed: "left",
      width: 200,
    },
    {
      title: "prix_ht_ini",
      dataIndex: "prix_ht_initial",
      fixed: "left",
    },
    {
      title: "client",
      dataIndex: "client",
      render: (_,row) => <Checkbox checked={row.client}
      onChange={(e) =>
        handleVenteRowValueChanged(
          row.index,
          "client",
          e.target.checked,
          row.type,
          row.prix_ht_initial
        )
      }/>
    },
    {
      title: "Amb",
      dataIndex: "ambassadeur",
      render: (_,row) => <Checkbox checked={row.ambassadeur}
      onChange={(e) =>
        handleVenteRowValueChanged(
          row.index,
          "ambassadeur",
          e.target.checked,
          row.type,
          row.prix_ht_initial
        )}/>
    },
   
    {
      title: "promo",
      dataIndex: "promotion",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "promotion",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.promotion}
        />
      ),
    },
    {
      title: "prix_ht",
      dataIndex: "prix_ht",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "prix_ht",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.prix_ht}
        />
      ),
    },
    {
      title: "pc",
      dataIndex: "pc",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "pc",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.pc}
        />
      ),
    },
    {
      title: "remise",
      dataIndex: "remise",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "remise",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.remise}
        />
      ),
    },
    {
      title: "fidelite",
      dataIndex: "fidelite",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "fidelite",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.fidelite}
        />
      ),
    },
    {
      title: "hc",
      dataIndex: "hc",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "hc",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.hc}
        />
      ),
    },
    {
      title: "challenge",
      dataIndex: "challenge",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "challenge",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.challenge}
        />
      ),
    },
    {
      title: "grade",
      dataIndex: "grade",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "grade",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.grade}
        />
      ),
    },
    {
      title: "client",
      dataIndex: "prime_client",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "prime_client",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.prime_client}
        />
      ),
    },
    {
      title: "dev",
      dataIndex: "prime_developpement",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "prime_developpement",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.prime_developpement}
        />
      ),
    },
    {
      title: "p.fid",
      dataIndex: "prime_fidelite",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "prime_fidelite",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.prime_fidelite}
        />
      ),
    },
    {
      title: "lead",
      dataIndex: "prime_leadership",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "prime_leadership",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.prime_leadership}
        />
      ),
    },
    {
      title: "exce",
      dataIndex: "prime_excellence",
      render: (_, row, index) => (
        <TableCellInput
          onChange={(e) =>
            handleVenteRowValueChanged(
              row.index,
              "prime_excellence",
              e.target.value,
              row.type,
              row.prix_ht_initial
            )
          }
          value={row.prime_excellence}
        />
      ),
    },
    {
      title: "actions",
      dataIndex: "actions",
      render: (_, row) => (
        <Space>
          <Button
            onClick={() => removeProduitFromVente(row.index, row.type)}
            type="link"
            size="small"
            icon={<DeleteOutlined />}
          ></Button>
        </Space>
      ),
      fixed: "right",
      width: 60,
    },
  ];

  return (
    <div>
      <PageTitle
        icon={
          <i
            style={{ fontSize: 24, marginRight: 4 }}
            className="fi fi-rr-money-check-edit"
          />
        }
        rightSide={
          <Button
            onClick={() => setFormMode("create")}
            type="primary"
            size={"small"}
            icon={<PlusOutlined />}
          >
            Ajouter
          </Button>
        }
        value={"Gestion des ventes"}
      />
      <AppTable
        size={"small"}
        bordered
        scroll={{ x: 1100 }}
        dataSource={rows}
        columns={columns}
      />
      <ErrorModal close={() => dispatch(setVenteState({field: "error", value:null}))} message={error} open={error !== null} />
      {/* form */}
      <Drawer
        width={"100%"}
        title={
          <Space className="justify-content-between">
            <Typography.Title level={5}>
              {formMode === "create"
                ? "Créer une nouvelle vente"
                : "Modification de la pormotion"}
            </Typography.Title>
            <Space>
              <Button
                onClick={() => setFormMode(null)}
                icon={<CloseOutlined />}
              >
                Annuler
              </Button>
              <Button
                onClick={() => {
                  formMode === "create"
                    ? dispatch(
                        venteThunk.createVente({
                          form,
                          venteProduits,
                          venteProduitsCadeaux,
                        })
                      )
                    : dispatch(
                        venteThunk.updateVente({
                          id: form.id,
                          data: {
                            form,
                            venteProduits,
                            venteProduitsCadeaux,
                          },
                        })
                      );
                }}
                type="primary"
                icon={<SaveOutlined />}
                disabled={status === STATE_STATUS.loading}
              >
                {status === STATE_STATUS.loading ? (
                  <Spin size="small" />
                ) : (
                  "Enregistrer"
                )}
              </Button>
            </Space>
          </Space>
        }
        placement="right"
        onClose={() => setFormMode(null)}
        open={formMode !== null}
      >
        <div className="row app-form">
          {/* form informations vente */}
          <div className="col-md-2 col-12 border-end">
            {/* title informations vente */}
            <h5>Informations de la vente</h5>
            <VerticalSpace>
              {/* input label */}
              <label htmlFor="label">Libellé</label>
              <Input
                id="label"
                onChange={(e) =>
                  dispatch(
                    setVenteForm({ field: "label", value: e.target.value })
                  )
                }
                value={form.label}
              />
              {/* input date début */}
              <label htmlFor="date_debut">Date début</label>
              <DatePicker
                allowClear={false}
                showTime
                format={"DD/MM/YYYY HH:mm"}
                id="date_debut"
                onChange={(date_debut) =>
                  dispatch(
                    setVenteForm({
                      field: "date_debut",
                      value: dayjsToSQLDate(date_debut),
                    })
                  )
                }
                value={SQLDateTodayjs(form.date_debut)}
              />
              {/* input date fin */}
              <label htmlFor="date_fin">Date fin</label>
              <DatePicker
                allowClear={false}
                showTime
                format={"DD/MM/YYYY HH:mm"}
                id="date_fin"
                onChange={(date_fin) =>
                  dispatch(
                    setVenteForm({
                      field: "date_fin",
                      value: dayjsToSQLDate(date_fin),
                    })
                  )
                }
                value={SQLDateTodayjs(form.date_fin)}
              />
              {/* input priorité */}
              <label htmlFor="priorite">Priorité</label>
              <Input
                id="priorite"
                onChange={(e) =>
                  dispatch(
                    setVenteForm({ field: "priorite", value: e.target.value })
                  )
                }
                value={form.priorite}
              />
              {/* input max cadeaux */}
              <label htmlFor="max_cadeaux">Max cadeaux</label>
              <Input
                id="max_cadeaux"
                onChange={(e) =>
                  dispatch(
                    setVenteForm({
                      field: "max_produits_cadeaux",
                      value: e.target.value,
                    })
                  )
                }
                value={form.max_produits_cadeaux}
              />
            </VerticalSpace>
          </div>
          {/* vente section produits et produits cadeaux */}
          <div className="col-12 col-md-10">
            <h5>Ajouter un produit à la vente</h5>
            {/* form insert produits */}
            <div className="row g-1">
              <div className="col-12 col-md-2">
                {/* selection catégorie */}
                <VerticalSpace>
                  <label htmlFor="categorie">Catégorie</label>
                  <Select
                    id="categorie"
                    value={filterForm.id_categorie}
                    onChange={(value) =>
                      handleChangeFilterForm("id_categorie", value)
                    }
                  >
                    <Select.Option value="">
                      Veuillez séléctionner
                    </Select.Option>
                    {categories.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.label}
                      </Select.Option>
                    ))}
                  </Select>
                </VerticalSpace>
              </div>
              {/* selection sous catégorie */}
              <div className="col-12 col-md-2">
                <VerticalSpace>
                  <label htmlFor="sous_categorie">Sous Catégorie</label>
                  <Select
                    id="sous_categorie"
                    value={filterForm.id_sous_categorie}
                    onChange={(value) =>
                      handleChangeFilterForm("id_sous_categorie", value)
                    }
                  >
                    <Select.Option value="">
                      Veuillez séléctionner
                    </Select.Option>
                    {sousCategories.map((sc) => (
                      <Select.Option key={sc.id} value={sc.id}>
                        {sc.label}
                      </Select.Option>
                    ))}
                  </Select>
                </VerticalSpace>
              </div>
              <div className="col-12 col-md-2">
                <VerticalSpace>
                  <label htmlFor="reference">Référence</label>
                  <Select
                    allowClear
                    onClear={() => handleChangeFilterForm("reference", "")}
                    id="reference"
                    showSearch
                    placeholder="Référence"
                    optionFilterProp="children"
                    onChange={(reference) =>
                      handleChangeFilterForm("reference", reference)
                    }
                    filterOption={(input, option) =>
                      (option?.value ?? "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={referencesProduits.map((option) => {
                      return {
                        value: option.reference,
                        label: option.reference,
                      };
                    })}
                  />
                </VerticalSpace>
              </div>

              <div className="col-12 col-md-4">
                <VerticalSpace>
                  <label htmlFor="produit">Produit</label>
                  <Select
                    allowClear
                    onClear={() => setSelectedProduit(null)}
                    id="produit"
                    showSearch
                    placeholder="Selection produit"
                    optionFilterProp="children"
                    onChange={(reference) => setSelectedProduit(reference)}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={filteredProduits.map((option) => {
                      return { value: option.reference, label: option.label };
                    })}
                  />
                </VerticalSpace>
              </div>
              <div className="col-12 col-md-1 d-flex align-items-end">
                <Button
                  disabled={selectedProduit === null}
                  onClick={() => addProduitToVente(selectedProduit, "produit")}
                >
                  <AppstoreFilled style={{ color: blue.primary }} />
                  Produit
                </Button>
              </div>
              <div className="col-12 col-md-1 d-flex align-items-end">
                <Button
                  disabled={selectedProduit === null}
                  onClick={() => addProduitToVente(selectedProduit, "cadeau")}
                >
                  <GiftFilled style={{ color: red.primary }} />
                  Cadeau
                </Button>
              </div>
            </div>
            {/* liste produits de la vente */}
            <div className="row">
              <div className="col-12">
                <Typography.Title className="mt-2" level={3}>
                  Produits de la vente{" "}
                  <span>
                    ({venteProduits.length}) Produits de total (
                    {venteProduits
                      .reduce((p, c) => p + Number(c.prix_ht), 0)
                      .toFixed(3)}
                    ) HT
                  </span>
                </Typography.Title>
                <AppTable
                  rowKey="index"
                  pagination={{
                    pageSize: 5,
                  }}
                  size={"small"}
                  bordered
                  scroll={{ x: 1500 }}
                  columns={columnsProduit}
                  dataSource={venteProduits}
                />
              </div>
              <div className="col-12">
                <Typography.Title level={3}>
                  Produits cadeaux de la vente{" "}
                  <span>
                    ({venteProduitsCadeaux.length}) Produits de total (
                    {venteProduitsCadeaux
                      .reduce((p, c) => p + Number(c.prix_ht), 0)
                      .toFixed(3)}
                    ) HT
                  </span>
                </Typography.Title>
                <AppTable
                  rowKey="index"
                  pagination={{
                    pageSize: 5,
                  }}
                  size={"small"}
                  bordered
                  scroll={{ x: 1500 }}
                  columns={columnsProduit}
                  dataSource={venteProduitsCadeaux}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default GestionVentes;
