import { Input } from "antd";
import { useEffect, useRef, useState } from "react";

export default function EditableTableCell({ initialValue = "", onEnter }) {
  const [value, setValue] = useState(initialValue);
  const [render, setRender] = useState("cell"); // cell or input
  const inputRef = useRef(null);
  useEffect(() => {
    if (render === "input") {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [render]);
  if (render === "cell")
    return <div onDoubleClick={() => setRender("input")}>{value}</div>;
  return (
    <Input
      ref={inputRef}
      onPressEnter={() => {
        onEnter(value);
        setRender("cell");
      }}
      value={value}
      onBlur={() => {
        onEnter(value);
        setRender("cell");
      }}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
