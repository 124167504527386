import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  categorieStateSelector,
  setCategorieState,
} from "../../../../store/reducers/categorieReducer";

import { gammeStateSelector } from "../../../../store/reducers/gammeReducer";
import categorieThunk from "../../../../store/thunks/categorieThunk";
import AppTable from "../../../components/AppTable";
import { IMG_URL } from "../../../../config/constants";
import FilesUploader from "../../../components/filesUploader";
import ErrorModal from "../../../components/errorModal";
export default function GestionCategories() {
  const dispatch = useDispatch();
  const {
    rows,
    createStatus,
    error,
    form,
    deleteStatus,
    formMode,
    images,
    actionFulfilled,
  } = useSelector(categorieStateSelector);
  const { rows: gammes } = useSelector(gammeStateSelector);
  const [formRef] = Form.useForm();
  const [parentValue, setParentValue] = useState("");
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (_, row) =>
        row.image !== "" ? (
          <Avatar src={`${IMG_URL}${row.image}`} alt="" />
        ) : null,
    },
    {
      title: "libellé",
      dataIndex: "label",
    },
    {
      title: "Séquence",
      dataIndex: "sequence",
    },
    {
      title: "Gamme",
      dataIndex: "label_gamme",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, row) => (
        <Space>
          <Button
            onClick={() => onUpdateClicked(row)}
            type="link"
            size="small"
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Confirmation de suppression"
            description="Vous êtes surs de vouloir supprimer cet entité?"
            onConfirm={() => deleteEntity(row.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
      width: 70,
    },
  ];
  const onClose = () => {
    dispatch(setCategorieState({ formMode: null }));
    formRef.setFieldsValue(form);
  };

  const setFormMode = (formMode) => {
    dispatch(setCategorieState({ formMode }));
  };
  const setImages = (images) => {
    dispatch(setCategorieState({ images }));
  };

  useEffect(() => {
    if (actionFulfilled) onClose();
  }, [actionFulfilled]);

  const submitForm = (values) => {
    if (values.id === undefined) dispatch(categorieThunk.create(values));
    else dispatch(categorieThunk.update({ id: values.id, data: values }));
  };

  const onUpdateClicked = (row) => {
    setFormMode("update");
    formRef.setFieldsValue(row);
    setParentValue(row.id_gamme);
    if (row.image !== "")
      setImages([{ url: IMG_URL + row.image, name: row.image }]);
  };

  const deleteEntity = (id) => {
    dispatch(categorieThunk.remove(id));
  };
  useEffect(() => {
    if (deleteStatus === "succeeded")
      message.success("Entité supprimée avec succès!");
  }, [deleteStatus]);

  const onParentSelected = (id) => {
    setParentValue(id);
    let p = gammes.find((p) => p.id === id);
    if (p !== undefined) {
      formRef.setFieldsValue({
        ...formRef.getFieldsValue,
        id_gamme: p.id,
      });
    } else {
      formRef.setFieldsValue({
        ...formRef.getFieldsValue,
        id_gamme: null,
      });
    }
  };
  useEffect(() => {
    if (formMode !== "update") setParentValue("");
  }, [formMode]);
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between my-2">
        <Typography.Title className="m-0" level={4}>
          Gestion des categories
        </Typography.Title>
        <Tooltip title="Ajouter categorie">
          <Button
            onClick={() => setFormMode("create")}
            type="primary"
            size={"small"}
            icon={<PlusOutlined />}
          >
            Ajouter
          </Button>
        </Tooltip>
      </div>
      <AppTable size={"small"} bordered columns={columns} dataSource={rows} />
      <Drawer
        title={
          formMode === "create"
            ? "Créer une nouvelle categorie"
            : "Modifier la categorie"
        }
        width={600}
        onClose={onClose}
        open={formMode !== null}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          initialValues={form}
          form={formRef}
          onFinish={submitForm}
          layout="vertical"
        >
          <Row gutter={16}>
            <FormItem name={"id"} hidden={true}>
              <Input />
            </FormItem>
            <FormItem name={"id_gamme"} hidden={true}>
              <Input />
            </FormItem>
            <Col span={12}>
              <Form.Item
                name="label"
                label="Libéllé"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le nom de la categorie",
                  },
                ]}
              >
                <Input placeholder="Nom de la categorie" id="input-label" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="sequence"
                label="Séquence"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le séquencement",
                  },
                ]}
              >
                <Input placeholder="Séquence" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="id_gamme"
                label="Gamme"
                rules={[
                  {
                    required: true,
                    message: "Veuillez sélectionner une gamme",
                  },
                ]}
              >
                <Select onChange={onParentSelected}>
                  <Select.Option value={""}></Select.Option>
                  {gammes.map((p) => (
                    <Select.Option value={p.id} key={p.id}>
                      {p.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="image" hidden={true}>
                <Input />
              </Form.Item>

              {formMode !== null && (
                <FilesUploader
                  initalFiles={images}
                  onServerUploaded={(files) =>
                    formRef.setFieldValue("image", files[0]?.name ?? "")
                  }
                  nbFiles={1}
                />
              )}
            </Col>
          </Row>

          <Space align="center" className="justify-content-end">
            <Button onClick={onClose} icon={<CloseOutlined />}>
              Annuler
            </Button>
            <Button
              loading={createStatus === "loading"}
              htmlType={"submit"}
              type="primary"
              icon={<SaveOutlined />}
            >
              Enregistrer
            </Button>
          </Space>
        </Form>
      </Drawer>
      <ErrorModal
        close={() => dispatch(setCategorieState({ error: null }))}
        message={error}
        open={error !== null}
      />
    </div>
  );
}
