import genericThunk from "./baseThunk";
import UserApi from "../../api/userApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

const Api = new UserApi();
const login = createAsyncThunk(`users/login`, async data => {
  return await Api.login(data);
});
const extraReducers = { login };
const userThunk = genericThunk(Api, extraReducers);
export default userThunk;
