// api/MyEntityApi.js
import axios from "axios";
import BaseApi from "./baseApi";
import { API_URL } from "../config/constants";

export default class UserApi extends BaseApi {
    constructor() {
        super("user");
    }

    async login(data) {
        return await 
        axios
        .post(API_URL + "/users/login", data, {
            headers:{
                "Content-Type": "application/json"
            }
        })
        .then(res => res.data)        
    }
}

