import {ChevronLeft, ChevronRight, ChevronsDown, Facebook, Instagram} from "react-feather";
import TopProducts from "./topProducts";
import {Fragment} from "react";
import Login from "./login";

export default function Landing(){
    return (
        <Fragment>
            <div id="hero">

                <div id="social-links">
                    <a href="https://www.facebook.com/acillacartajlife/" target={"_blank"} className={"social-link"}><Facebook/></a>
                    <a href="https://www.instagram.com/acilla_officiel/?hl=en" target={"_blank"} className={"social-link"}><Instagram/></a>
                </div>

                <div id="hero-navs">
                    <div className="hero-nav"><ChevronLeft/></div>
                    <div className="hero-nav"><ChevronRight/></div>
                </div>

                <a href="#top-products">
                    <div className="scroll-down-arrow">
                        <div className="arrow bounce">
                            <ChevronsDown/>
                            <div className="circle circle1"/>
                            <div className="circle circle2"/>
                            <div className="circle circle3"/>
                        </div>
                    </div>
                </a>

            </div>
            <TopProducts/>
            <Login/>
        </Fragment>
    )
}
