import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useDispatch, useSelector } from "react-redux";
import {
  gammeStateSelector,
  setGammeState,
} from "../../../../store/reducers/gammeReducer";

import { ArrowRightCircle } from "react-feather";
import {
  IMG_URL,
  STATE_STATUS,
  poidsTableColumns,
} from "../../../../config/constants";
import gammeThunk from "../../../../store/thunks/gammeThunk";
import AppTable from "../../../components/AppTable";
import ErrorModal from "../../../components/errorModal";
import FilesUploader from "../../../components/filesUploader";
export default function GestionGammes() {
  const dispatch = useDispatch();
  const { rows, error, form, status, images, formMode } = useSelector(gammeStateSelector);
  const [formRef] = Form.useForm();
  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (_, row) =>
        row.image !== "" ? (
          <Avatar src={`${IMG_URL}${row.image}`} alt="" />
        ) : null,
    },
    {
      title: "libellé",
      dataIndex: "label",
    },
    ...poidsTableColumns,
    {
      title: "Actions",
      fixed: "right",
      render: (_, row) => (
        <Space>
          <Button
            onClick={() => onUpdateClicked(row.id)}
            type="link"
            size="small"
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Confirmation de suppression"
            description="Vous êtes surs de vouloir supprimer cet entité?"
            onConfirm={() => deleteEntity(row.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
      width: 70,
    },
  ];
  const onClose = () => {
    dispatch(setGammeState({field: "formMode", value: null}))
    clearForm();
  };

  const clearForm = () => {
    formRef.setFieldsValue(form);
    dispatch(setGammeState({field: "images", value: null}))
  };

  const setFormMode = value => {
    dispatch(setGammeState({field: "formMode", value}))
  }

  const populateAllPoids = (value) => {
    let formInstance = {};
    Object.keys(form).forEach((key) => {
      if (!["label", "id", "image"].includes(key)) {
        formInstance[key] = value;
      } else {
        formInstance[key] = formRef.getFieldsValue()[key];
      }
    });
    formRef.setFieldsValue(formInstance);
  };

  const submitForm = (values) => {
    if (values.id === undefined) dispatch(gammeThunk.create(values));
    else dispatch(gammeThunk.update({ id: values.id, data: values }));
    onClose();
  };

  const onUpdateClicked = (id) => {
    dispatch(gammeThunk.prepareForUpdate({id, formRef}))
  };

  const deleteEntity = (id) => {
    dispatch(gammeThunk.remove(id));
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between my-2">
        <Typography.Title className="m-0" level={4}>
          Gestion des gammes
        </Typography.Title>
        <Tooltip title="Ajouter gamme">
          <Button
            onClick={() => setFormMode("create")}
            type="primary"
            size={"small"}
            icon={<PlusOutlined />}
          >
            Ajouter
          </Button>
        </Tooltip>
      </div>
      <AppTable
        bordered
        scroll={{ x: 1100 }}
        columns={columns}
        dataSource={rows}
      />
      <ErrorModal
        close={() => dispatch(setGammeState({ field: "error", value: null }))}
        message={error}
        open={error !== null}
      />
      <Drawer
        title={
          formMode === "create"
            ? "Créer une nouvelle gamme"
            : "Modifier la gamme"
        }
        width={920}
        onClose={onClose}
        open={formMode !== null}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          id="form"
          initialValues={form}
          form={formRef}
          onFinish={submitForm}
          layout="vertical"
        >
          <Row gutter={16}>
            <FormItem name={"id"} hidden={true}>
              <Input />
            </FormItem>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="label"
                label="Libéllé"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le nom de la gamme",
                  },
                ]}
              >
                <Input placeholder="Nom de la gamme" id="input-label" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={4}>
              <Form.Item label="Poids Général">
                <Space.Compact
                  style={{
                    width: "100%",
                  }}
                >
                  <InputNumber
                    id="poids-general"
                    onPressEnter={(e) => {
                      e.preventDefault();
                      populateAllPoids(e.target.value);
                    }}
                  />
                  <Button
                    onClick={() =>
                      populateAllPoids(
                        document.getElementById("poids-general").value
                      )
                    }
                    type="primary"
                  >
                    <ArrowRightCircle />
                  </Button>
                </Space.Compact>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="pc"
                label="PC"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir la pc",
                  },
                ]}
              >
                <Input placeholder="PC" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="remise"
                label="Remise"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir la remise",
                  },
                ]}
              >
                <Input placeholder="Remise" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="fidelite"
                label="Fidélité"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir la fidélité",
                  },
                ]}
              >
                <Input placeholder="Fidélité" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="hc"
                label="HC"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le HC",
                  },
                ]}
              >
                <Input placeholder="HC" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="challenge"
                label="Challenge"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le challenge",
                  },
                ]}
              >
                <Input placeholder="Challenge" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="grade"
                label="Grade"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir la grade",
                  },
                ]}
              >
                <Input placeholder="Grade" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="prime_client"
                label="Client"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le client",
                  },
                ]}
              >
                <Input placeholder="Client" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="prime_developpement"
                label="Développement"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir la prime du développement",
                  },
                ]}
              >
                <Input placeholder="Développement" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="prime_fidelite"
                label="Prime Fidélité"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir la prime de fidélité",
                  },
                ]}
              >
                <Input placeholder="Prime de Fidélité" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="prime_leadership"
                label="Leadership"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir la prime de leadership",
                  },
                ]}
              >
                <Input placeholder="Leadership" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4}>
              <Form.Item
                name="prime_excellence"
                label="Excellence"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir la prime d'excellence",
                  },
                ]}
              >
                <Input placeholder="Excellence" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="image" hidden={true}>
                <Input />
              </Form.Item>
              {images !== null && (
                <FilesUploader
                  initalFiles={images}
                  onServerUploaded={(files) =>
                    formRef.setFieldValue("image", files[0]?.name ?? "")
                  }
                  nbFiles={1}
                />
              )}
            </Col>
          </Row>

          <Space align="center" justify="end">
            <Button onClick={onClose} icon={<CloseOutlined />}>
              Annuler
            </Button>
            <Button
              name="save"
              loading={status === STATE_STATUS.loading}
              htmlType={"submit"}
              type="primary"
              icon={<SaveOutlined />}
            >
              Enregistrer
            </Button>
          </Space>
          <p>{error}</p>
        </Form>
      </Drawer>
    </div>
  );
}
