import genericThunk from "./baseThunk";
import GammeApi from "../../api/gammeApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

const Api = new GammeApi();
const prepareForUpdate = createAsyncThunk("gamme/prepare-for-update", async ({id, formRef}, thunkAPI) => {
    try {
        const gamme = await Api.get(id)
        formRef.setFieldsValue(gamme)
        return gamme
    } catch (error) {
        return thunkAPI.rejectWithValue(JSON.stringify(error))
    }
})
const gammeThunk = genericThunk(Api, {prepareForUpdate})
export default gammeThunk
