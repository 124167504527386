import {
  AlertOutlined,
  AppstoreOutlined,
  ArrowsAltOutlined,
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  CheckSquareOutlined,
  DeliveredProcedureOutlined,
  DeploymentUnitOutlined,
  FileTextOutlined,
  IdcardOutlined,
  PayCircleOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { BiSolidUserDetail } from "react-icons/bi";
import { CiBoxes } from "react-icons/ci";
import { FaMoneyBillWave, FaTruck } from "react-icons/fa";
import {
  FcAssistant,
  FcBarChart,
  FcFolder,
  FcMindMap,
  FcMultipleInputs,
  FcParallelTasks,
  FcReading,
  FcSalesPerformance,
  FcSettings,
  FcWorkflow
} from "react-icons/fc";
import { GiLaurelsTrophy } from "react-icons/gi";
import { HiOutlineClipboard } from "react-icons/hi";
import {
  PiGiftLight,
  PiPercentFill,
  PiPercentLight,
  PiTruckLight,
  PiUserFocusLight
} from "react-icons/pi";
export const adminNavigationItems = [
  {
    key: "1",
    icon: <FcBarChart />,
    label: "Dashboard",
    link: "/admin",
  },
  {
    key: "2",
    icon: <FcParallelTasks />,
    label: "Gestion du stock",
    children: [
      {
        key: "2.1",
        icon: <CiBoxes />,
        label: "Gestion des produits",
        link: "/admin/gestion-produits",
      },
      {
        key: "2.2",
        icon: <ArrowsAltOutlined />,
        label: "Mouvements des produits",
        link: "/admin/mouvements-produits",
      },
      {
        key: "2.3",
        icon: <DeploymentUnitOutlined />,
        label: "Nomenclature",
        link: "/admin/nomenclature",
      },
      {
        key: "2.4",
        icon: <AuditOutlined />,
        label: "Réservation du stock",
        link: "/admin/reservation-stock",
      },
      {
        key: "2.5",
        icon: <CheckSquareOutlined />,
        label: "Inventaire",
        link: "/admin/inventaire",
      },
      {
        key: "2.6",
        icon: <BarChartOutlined />,
        label: "Statistiques de stock",
        link: "/admin/statistiques-stock",
      },
      {
        key: "2.7",
        icon: <AlertOutlined />,
        label: "Stock d'alerte",
        link: "/admin/stock-alerte",
      },
    ],
  },
  {
    key: "3",
    icon: <FcSalesPerformance />,
    label: "Gestion des ventes",
    children: [
      {
        key: "3.1",
        icon: <UserOutlined />,
        label: "Gestion des clients",
        link: "/admin/gestion-clients",
      },
      {
        key: "3.2",
        icon: <FileTextOutlined />,
        label: "Documents ventes",
        link: "/admin/documents-ventes",
      },
      {
        key: "3.3",
        icon: <ShoppingCartOutlined />,
        label: "Vente personnalisée",
        link: "/admin/ventes",
      },
      {
        key: "3.4",
        icon: <BarChartOutlined />,
        label: "Statistiques de ventes",
        link: "/admin/statistiques-ventes",
      },
      {
        key: "3.5",
        icon: <DeliveredProcedureOutlined />,
        label: "Dispatching",
        link: "/admin/dispatching",
      },
    ],
  },
  {
    key: "4",
    icon: <FcMultipleInputs />,
    label: "Gestion des achats",
    children: [
      {
        key: "4.1",
        icon: <UserOutlined />,
        label: "Gestion des fournisseurs",
        link: "/admin/gestion-fournisseurs",
      },
      {
        key: "4.2",
        icon: <FileTextOutlined />,
        label: "Documents achat",
        link: "/admin/documents-achats",
      },
      {
        key: "4.3",
        icon: <AppstoreOutlined />,
        label: "Gestion des dépôts",
        link: "/admin/depots",
      },
      {
        key: "4.4",
        icon: <BarChartOutlined />,
        label: "Statistiques des achats",
        link: "/admin/statistiques-achats",
      },
    ],
  },
  {
    key: "5",
    icon: <FcFolder />,
    label: "Comptabilité",
    children: [
      {
        key: "5.1",
        icon: <PiUserFocusLight />,
        label: "Règlements clients",
        link: "/admin/reglements-clients",
      },
      {
        key: "5.2",
        icon: <PiTruckLight />,
        label: "Règlements Fournisseurs",
        link: "/admin/reglements-fournisseurs",
      },
      {
        key: "5.3",
        icon: <BankOutlined />,
        label: "État de caisse",
        link: "/admin/caisse",
      },
      {
        key: "5.4",
        icon: <IdcardOutlined />,
        label: "Carte Acilla",
        link: "/admin/carte-acilla",
      },
      {
        key: "5.5",
        icon: <HiOutlineClipboard />,
        label: "Relevés",
        link: "/admin/releves",
      },
    ],
  },
  {
    key: "6",
    icon: <FcWorkflow />,
    label: "Bonus et challenges",
    children: [
      {
        key: "6.1",
        icon: <PiPercentLight />,
        label: "Bonus",
        link: "/admin/bonus",
      },
      {
        key: "6.2",
        icon: <GiLaurelsTrophy />,
        label: "Challenges",
        link: "/admin/challenges",
      },
      {
        key: "6.3",
        icon: <PiGiftLight />,
        label: "Happy Cadeaux",
        link: "/admin/happy-cadeaux",
      },
    ],
  },
  {
    key: "7",
    icon: <FcReading/>,
    label: "Nos Catalogues",
    link: "/admin/catalogues"
  },
  {
    key: "8",
    icon: <FcMindMap/>,
    label: "Plan marketing",
    link: "/admin/plan-marketing"
  },
  {
    key: "9",
    icon: <FcAssistant/>,
    label: "Gestion de l'équipe",
    link: "/admin/equipe"
  },
  {
    key: "10",
    icon: <FcSettings />,
    label: "Paramètres",
    children: [
      {
        key: "10.1",
        icon: <PayCircleOutlined />,
        label: "Frais et taxes",
        link: "/admin/parametres/frais-taxes",
      },
      {
        key: "10.2",
        icon: <FaTruck />,
        label: "Transporteurs",
        link: "/admin/parametres/transporteurs",
      },
      {
        key: "10.3",
        icon: <PiPercentFill />,
        label: "Gestion des poids",
        link: "/admin/parametres/poids",
      },
      {
        key: "10.4",
        icon: <BiSolidUserDetail />,
        label: "Rôles utilisateurs",
        link: "/admin/parametres/roles-utilisateurs",
      },
      {
        key: "10.10",
        icon: <FaMoneyBillWave />,
        label: "Mode paiements",
        link: "/admin/parametres/modes-paiements",
      },
    ],
  },
];

export const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://api.kreatek.tn/cartajlife";
export const IMG_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001/uploads/"
    : "https://api.kreatek.tn/cartajlife/uploads/";

export const STATE_STATUS = {
  idle: "idle",
  loading: "loading",
  error: "error",
};

export const poidsTableColumns = [
  {
    title: "Pc",
    dataIndex: "pc",
  },
  {
    title: "Remise",
    dataIndex: "remise",
  },
  {
    title: "Fidélité",
    dataIndex: "fidelite",
  },
  {
    title: "Hc",
    dataIndex: "hc",
  },
  {
    title: "Challenge",
    dataIndex: "challenge",
  },
  {
    title: "Grade",
    dataIndex: "grade",
  },
  {
    title: "Client",
    dataIndex: "prime_client",
  },
  {
    title: "Dév.",
    dataIndex: "prime_developpement",
  },
  {
    title: "P.Fid",
    dataIndex: "prime_fidelite",
  },
  {
    title: "Lead.",
    dataIndex: "prime_leadership",
  },
  {
    title: "Excell.",
    dataIndex: "prime_excellence",
  },
];
