import axios from "axios";
import { API_URL } from "./constants";

// const axiosInstance = axios.create();

// // Function to set the authorization token in the request headers
// const setAuthToken = (token) => {
//   if (token) {
//     axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//   } else {
//     delete axiosInstance.defaults.headers.common['Authorization'];
//   }
// };

// // Interceptor to automatically set the authorization token before each request
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token');
//     setAuthToken(token);
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
const axiosInstance = axios.create({
  baseURL: API_URL, // Set your API base URL
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  },
});

export const axiosPost = axios.create({
  baseURL: API_URL, // Set your API base URL
  headers: {
    "Content-Type": "application/json",
    common: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  },
});

export default axiosInstance;
