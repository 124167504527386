import { createSlice } from "@reduxjs/toolkit";
import sousCategorieThunk from "../thunks/sousCategorieThunk";
const initialState = {
  rows: [],
  status: "idle",
  createStatus: "idle",
  updateStatus: "idle",
  deleteStatus: "idle",
  error: null,
  form: {
    id: undefined,
    id_categorie: null,
    label: "",
    image: "",
    pc: 0,
    remise: 0,
    fidelite: 0,
    hc: 0,
    challenge: 0,
    grade: 0,
    prime_client: 0,
    prime_developpement: 0,
    prime_fidelite: 0,
    prime_leadership: 0,
    prime_excellence: 0,
  },
};
const slice = createSlice({
  name: "SousCategorie",
  initialState,
  reducers: {
    setRows: (state, action) => {
      state.rows = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(sousCategorieThunk.getAll.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sousCategorieThunk.getAll.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rows = action.payload;
      })
      .addCase(sousCategorieThunk.getAll.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(sousCategorieThunk.create.pending, (state) => {
        state.createStatus = "loading";
      })
      .addCase(sousCategorieThunk.create.fulfilled, (state, action) => {
        state.createStatus = "succeeded";
        state.rows.push(action.payload);
        state.form = initialState.form;
      })
      .addCase(sousCategorieThunk.create.rejected, (state, action) => {
        state.createStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(sousCategorieThunk.update.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(sousCategorieThunk.update.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        const index = state.rows.findIndex(
          (rows) => rows.id === action.payload.id
        );
        if (index !== -1) {
          state.rows[index] = action.payload;
        }
      })
      .addCase(sousCategorieThunk.update.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(sousCategorieThunk.remove.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(sousCategorieThunk.remove.fulfilled, (state, action) => {
        //     state.deleteStatus = "succeeded";
        state.rows = state.rows.filter(
          (rows) => rows.id !== Number(action.payload.id)
        );
      })
      .addCase(sousCategorieThunk.remove.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(sousCategorieThunk.get.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sousCategorieThunk.get.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.rows.findIndex(
          (rows) => rows.id === action.payload.id
        );
        if (index !== -1) {
          state.rows[index] = action.payload;
        }
      })
      .addCase(sousCategorieThunk.get.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setRows } = slice.actions;
export const sousCategorieStateSelector = (state) => state.sousCategorie;
export default slice.reducer;
