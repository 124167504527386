import { Typography } from "antd";

export default function ModuleTitle({value, children}){
return (
    <div className="d-flex align-items-center justify-content-between my-2">
        <Typography.Title level={3}>
          {value}
        </Typography.Title>
        {children}
        </div>
)
}