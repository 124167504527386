import { Breadcrumb, Button } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import {
  gestionProduitsActions,
  gestionProduitsStateSelector,
} from "../../../../store/reducers/gestionProduitsReducer";

export default function GestionProduits() {
  const { activeTab } = useSelector(gestionProduitsStateSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    switch (activeTab) {
      case "1":
        navigate("/admin/gestion-produits/gammes");
        break;
      case "2":
        navigate("/admin/gestion-produits/categories");
        break;
      case "3":
        navigate("/admin/gestion-produits/sub-categories");
        break;
      default:
        navigate("/admin/gestion-produits/products");
        break;
    }
  }, [activeTab, navigate]);
  return (
    <div>
      <Breadcrumb
        className="bg-light py-2 px-1"
        items={[
          {
            title: (
              <Button
                type={activeTab === "1" ? "primary" : "text"}
                size="small"
                onClick={() =>
                  dispatch(gestionProduitsActions.setActiveTab("1"))
                }
              >
                Gammes
              </Button>
            ),
          },
          {
            title: (
              <Button
                type={activeTab === "2" ? "primary" : "text"}
                size="small"
                onClick={() =>
                  dispatch(gestionProduitsActions.setActiveTab("2"))
                }
              >
                Catégories
              </Button>
            ),
          },
          {
            title: (
              <Button
                type={activeTab === "3" ? "primary" : "text"}
                size="small"
                onClick={() =>
                  dispatch(gestionProduitsActions.setActiveTab("3"))
                }
              >
                Sous Catégories
              </Button>
            ),
          },
          {
            title: (
              <Button
                type={activeTab === "4" ? "primary" : "text"}
                size="small"
                onClick={() =>
                  dispatch(gestionProduitsActions.setActiveTab("4"))
                }
              >
                Produits
              </Button>
            ),
          },
        ]}
      />
      <Outlet />
    </div>
  );
}
