import {ChevronDown} from "react-feather";
import Product from "../../components/product";

export default function Shop() {
    return (
        <div className="container-fluid flex-grow-1">
            <div className="row flex-nowrap h-100">
                <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 border-end">
                    <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2">
                        <a href="/" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-decoration-none">
                            <span className="fs-5 d-none d-sm-inline text-dark">ACILLA</span>
                        </a>
                        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100" id="menu">
                            <li className={"w-100"}>
                                <a href="#submenu1" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                                    <i className="fs-4 bi-speedometer2"/>
                                    <p className="ms-1 d-flex align-items-center justify-content-between w-100">VISAGE <ChevronDown/></p>
                                </a>
                                <ul className="collapse  nav flex-column ms-2" id="submenu1" data-bs-parent="#menu">
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 1</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 2</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 3</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 4</span> </a>
                                    </li>
                                </ul>
                            </li>
                            <li className={"w-100"}>
                                <a href="#submenu2" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                                    <i className="fs-4 bi-speedometer2"/>
                                    <p className="ms-1 d-flex align-items-center justify-content-between w-100">CORPS <ChevronDown/></p>
                                </a>
                                <ul className="collapse nav flex-column ms-2" id="submenu2" data-bs-parent="#menu">
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 1</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 2</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 3</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 4</span> </a>
                                    </li>
                                </ul>
                            </li>
                            <li className={"w-100"}>
                                <a href="#submenu3" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                                    <i className="fs-4 bi-speedometer2"/>
                                    <p className="ms-1 d-flex align-items-center justify-content-between w-100">CHEVEUX <ChevronDown/></p>
                                </a>
                                <ul className="collapse nav flex-column ms-2" id="submenu3" data-bs-parent="#menu">
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 1</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 2</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 3</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 4</span> </a>
                                    </li>
                                </ul>
                            </li>
                            <li className={"w-100"}>
                                <a href="#submenu4" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                                    <i className="fs-4 bi-speedometer2"/>
                                    <p className="ms-1 d-flex align-items-center justify-content-between w-100">ARGILE <ChevronDown/></p>
                                </a>
                                <ul className="collapse nav flex-column ms-2" id="submenu4" data-bs-parent="#menu">
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 1</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 2</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 3</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 4</span> </a>
                                    </li>
                                </ul>
                            </li>
                            <li className={"w-100"}>
                                <a href="#submenu5" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                                    <i className="fs-4 bi-speedometer2"/>
                                    <p className="ms-1 d-flex align-items-center justify-content-between w-100">HUILE <ChevronDown/></p>
                                </a>
                                <ul className="collapse nav flex-column ms-2" id="submenu5" data-bs-parent="#menu">
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 1</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 2</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 3</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 4</span> </a>
                                    </li>
                                </ul>
                            </li>
                            <li className={"w-100"}>
                                <a href="#submenu6" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                                    <i className="fs-4 bi-speedometer2"/>
                                    <p className="ms-1 d-flex align-items-center justify-content-between w-100">TISANES <ChevronDown/></p>
                                </a>
                                <ul className="collapse nav flex-column ms-2" id="submenu6" data-bs-parent="#menu">
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 1</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 2</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 3</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 4</span> </a>
                                    </li>
                                </ul>
                            </li>
                            <li className={"w-100"}>
                                <a href="#submenu7" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                                    <i className="fs-4 bi-speedometer2"/>
                                    <p className="ms-1 d-flex align-items-center justify-content-between w-100">LINGE DE MAISON <ChevronDown/></p>
                                </a>
                                <ul className="collapse nav flex-column ms-2" id="submenu7" data-bs-parent="#menu">
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 1</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 2</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 3</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 4</span> </a>
                                    </li>
                                </ul>
                            </li>
                            <li className={"w-100"}>
                                <a href="#submenu8" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                                    <i className="fs-4 bi-speedometer2"/>
                                    <p className="ms-1 d-flex align-items-center justify-content-between w-100">PARFUMS <ChevronDown/></p>
                                </a>
                                <ul className="collapse nav flex-column ms-2" id="submenu8" data-bs-parent="#menu">
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 1</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 2</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 3</span> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="nav-link py-0"> <span className="d-none d-sm-inline">Item 4</span> </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        {/*<hr/>*/}
                        {/*<div className="dropdown pb-4">*/}
                        {/*    <a href="#"*/}
                        {/*       className="d-flex align-items-center text-decoration-none dropdown-toggle"*/}
                        {/*       id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">*/}
                        {/*        <img src="https://github.com/mdo.png" alt="hugenerd" width="30"*/}
                        {/*             height="30" className="rounded-circle"/>*/}
                        {/*        <span className="d-none d-sm-inline mx-1">loser</span>*/}
                        {/*    </a>*/}
                        {/*    <ul className="dropdown-menu dropdown-menu-dark text-small shadow">*/}
                        {/*        <li><a className="dropdown-item" href="#">New project...</a></li>*/}
                        {/*        <li><a className="dropdown-item" href="#">Settings</a></li>*/}
                        {/*        <li><a className="dropdown-item" href="#">Profile</a></li>*/}
                        {/*        <li>*/}
                        {/*            <hr className="dropdown-divider"/>*/}
                        {/*        </li>*/}
                        {/*        <li><a className="dropdown-item" href="#">Sign out</a></li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="col py-3" id={"shop-content"}>
                    <div className="row gy-3">
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
