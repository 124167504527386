import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  notification,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  userActions,
  userStateSelector,
} from "../../store/reducers/userReducer";
import { LoginOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import userThunk from "../../store/thunks/userThunk";
import { STATE_STATUS } from "../../config/constants";
export default function Login() {
  // responsive login form
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 576); // Adjust the breakpoint as needed
    };

    // Set initial state
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { showLogin, error } = useSelector(userStateSelector);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(userActions.closeLogin());
  };
  const onFinish = (values) => {
    dispatch(userThunk.login(values));
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api.open({
      message: "Message d'erreur!",
      description: error,
      onClose: () => dispatch(userActions.setStatus(STATE_STATUS.idle)),
      // duration: 0
    });
  };

  useEffect(() => {
    if(error){
      openNotification()
    }
  }, [error])

  return (
    <Drawer
      title="Se connecter à votre compte Cartaj'life"
      placement="right"
      onClose={onClose}
      open={showLogin}
      width={isSmallScreen ? "100vw" : 500}
    >
      {contextHolder}
      <div className="d-flex flex-column">
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item label="Login" name="login" required>
            <Input type="email" placeholder="email" required />
          </Form.Item>
          <Form.Item label="Mot de passe" required name="password">
            <Input type="password" required />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <Row gutter={8}>
              <Col flex="auto">
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>Restez connecté</Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    icon={<LoginOutlined />}
                  >
                    Se connecter
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form>
        <p style={{ textAlign: "right", marginBottom: 16 }}>
          Vous n'avez pas de compte?{" "}
          <Button style={{ padding: 0 }} type="link">
            en créer un
          </Button>
        </p>
        <div style={{ flex: 1 }}>
          <img src="logo.png" alt="logo" height="100%" width={"100%"} />
        </div>
      </div>
    </Drawer>
  );
}
