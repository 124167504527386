// api/MyEntityApi.js
import axiosInstance, { axiosPost } from "../config/axiosInstance";
import BaseApi from "./baseApi";

export default class FraisTaxesApi extends BaseApi {
  constructor() {
    super("frais-taxes");
  }

  async getAll(filters) {
    return await axiosPost.post(`/${this.resource}/filtered`, filters);
  }
  async getTaxesAnnee() {
    return await axiosInstance
      .get(`/${this.resource}/taxes-annee`)
      .then((res) => res.data)
      .catch((error) => {
        console.log(error);
        return [];
      });
  }
}
