import SectionTitle from "../../components/section-title";
import Product from "../../components/product";
import {ChevronLeft, ChevronRight} from "react-feather";

export default function TopProducts(){
    return (
        <section id={"top-products"}>
                <SectionTitle text={"Top Produits"}/>
                <div className="container-fluid">
                    <div className="row align-items-center">

                        <div className="col-12 col-md-2 text-center">
                            <ChevronLeft/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2">
                            <Product/>
                        </div>
                        <div className="col-12 col-md-2 text-center">
                            <ChevronRight/>
                        </div>
                    </div>
                </div>
        </section>
    )
}
