import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    activeTab: "1"
}

const gestionProduitsSlice = createSlice({
    initialState,
    name: "gestionProduits",
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload
        }
    }
})

export default gestionProduitsSlice.reducer
export const gestionProduitsStateSelector = state => state.gestionProduits
const {setActiveTab} = gestionProduitsSlice.actions
export const gestionProduitsActions = {
    setActiveTab
}
