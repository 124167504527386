import { Input } from "antd";

export default function TableCellInput({
  width = 55,
  fontSize = 12,
  padding = 4,
  ...otherProps
}) {
  return <Input  onClick={e => e.target.select()} style={{ width: width, fontSize: fontSize, padding: padding }} {...otherProps} />;
}
