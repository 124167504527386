import genericThunk from "./baseThunk";
import ProduitApi from "../../api/produitApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setProduitOldValues,
  setProduitState,
} from "../reducers/produitReducer";
import { poidsTableColumns } from "../../config/constants";
import { preparePoidsRowsAndColumns } from "../../utils/produits_utils";

const Api = new ProduitApi();
const getFilteredProduitsList = createAsyncThunk(
  "produit/filtered-list",
  async (data, thunkAPI) => {
    try {
      return await Api.getFilteredProduitsList(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
// produit poids entities are ==> prix ht, prix ttc, id categorie
const handleChangeProduitPoidsEntities = createAsyncThunk(
  "produit/poids-entities-changed",
  async (data, thunkAPI) => {
    try {
      const { field, value, formRef } = data;
      const state = thunkAPI.getState();
      const { dispatch } = thunkAPI;
      const { taxes, poids, sousCategories, old_reference, old_categorie } =
        state.produit;
      if (field === "prix_ttc") {
        if (poids === null) {
          return thunkAPI.rejectWithValue("Veuillez choisir une catégorie!");
        }
        dispatch(setProduitState({ field: "prix_ttc", value }));

        // get taxes client
        // calculer ht_client
        dispatch(
          setProduitState({
            field: "prix_ht_client",
            value: taxes
              .filter((t) => t.client_assujetti)
              .reduce(
                (total, taxe) => total / (1 + Number(taxe.valeur) / 100),
                Number(value)
              )
              .toFixed(3),
          })
        );

        // get taxes ambassadeur
        // calculer ht_ambassadeur
        dispatch(
          setProduitState({
            field: "prix_ht_ambassadeur",
            value: taxes
              .filter((t) => t.ambassadeur_assujetti)
              .reduce(
                (total, taxe) => total / (1 + Number(taxe.valeur) / 100),
                Number(value)
              )
              .toFixed(3),
          })
        );
      }
      if (field === "id_categorie") {
        dispatch(
          setProduitState({
            field: "sousCategoriesCategorie",
            value: sousCategories.filter((c) => c.id_categorie === value),
          })
        );
        formRef.setFieldValue("id_sous_categorie", "");
        // get poids de la gamme parent de cette categorie
        const categorieData = await Api.getMaxReferenceByCategorieId(value);
        //   set poids
        dispatch(
          setProduitState({ field: "poids", value: categorieData.poids })
        );
        dispatch(
          setProduitState({
            field: "baseCalcul",
            value: categorieData.baseCalcul,
          })
        );
        // get max référence ==> si old_categorie --> set old_reference sinon set max référence
        formRef.setFieldValue(
          "reference",
          old_categorie === value ? old_reference : categorieData.max_reference
        );
      }
      const {
        baseCalcul,
        poids: newPoids,
        prix_ht_ambassadeur,
        prix_ttc,
      } = thunkAPI.getState().produit;
      formRef.setFieldValue("prix_ht", prix_ht_ambassadeur);
      const { poidsRows, columnsPoids } = preparePoidsRowsAndColumns(
        newPoids,
        baseCalcul,
        prix_ht_ambassadeur,
        prix_ttc
      );
      dispatch(setProduitState({ field: "columnsPoids", value: columnsPoids }));
      dispatch(setProduitState({ field: "poidsRows", value: poidsRows }));

      return true;
    } catch (error) {
      return thunkAPI.rejectWithValue(JSON.stringify(error));
    }
  }
);

const prepareProduitForUpdateOrDuplicate = createAsyncThunk(
  "/produit/prepare-for-update-or-duplicate",
  async ({ id, action, formRef }, thunkAPI) => {
    try {
      const produit = await Api.get(id);
      const categorieData = await Api.getMaxReferenceByCategorieId(
        produit.id_categorie
      );
      formRef.setFieldsValue(produit);
      if (action === "create") {
        formRef.setFieldValue("id", undefined);
        formRef.setFieldValue("reference", categorieData.max_reference);
      }

      return { produit, categorieData, action };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const produitThunk = genericThunk(Api, {
  getFilteredProduitsList,
  handleChangeProduitPoidsEntities,
  prepareProduitForUpdateOrDuplicate,
});
export default produitThunk;
