import { createAsyncThunk } from "@reduxjs/toolkit"
import FraisTaxesApi from "../../api/fraisTaxesApi"
import genericThunk from "./baseThunk"


const api = new FraisTaxesApi()
const getAll = createAsyncThunk(`frais-taxes/filtered`, async (data, thunkAPI) => {
    try {
        return await api.getAll(data).then(res => res.data)
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.message)
    }
})

const fraisTaxesThunk = genericThunk(api, {getAll})
export default fraisTaxesThunk