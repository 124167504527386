import { useEffect, useState } from "react";
import PoidsApi from "../../../api/poidsApi";
import ModuleTitle from "../../components/ModuleTitle";
import { Checkbox, Switch } from "antd";
import AppTable from "../../components/AppTable";

const api = new PoidsApi();
export default function GestionPoids() {
  const [rows, setRows] = useState([]);
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50
    },
    {
      title: "label",
      dataIndex: "label",
      width: 260
    },
    {
      title: "Calcul Sur",
      dataIndex: "calcul_sur",
      render: (_, row) => (
        <Switch
        style={{marginTop: 4, marginBottom: 4}}
          onChange={(checked) => updatePoidsCalculSur(row.id, checked)}
          defaultChecked={row.calcul_sur === "TTC"}
          checkedChildren={"TTC"}
          unCheckedChildren={"HT"}
        />
      ),
    },
  ];

  useEffect(() => {
    api.getAll().then((res) => setRows(res));
  }, []);
  const updatePoidsCalculSur = (id, checked) => {
    const calcul_sur = checked ? "TTC" : "HT";
    api.update(id, {calcul_sur}).then((res) => console.log(res));
  };
  return (
    <div>
      <ModuleTitle value={"Gestion des poids"} />
      <AppTable
        dataSource={rows}
        columns={columns}
        loading={rows.length === 0}
      />
    </div>
  );
}
