import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  adminNavigationItems
} from "../../config/constants";
import { adminActions } from "../../store/reducers/adminReducer";

export default function SideMenu({ Sider }) {
  const dispatch = useDispatch();
  const handleAddTab = (tab) => {
    const newTab = {
      id: tab.key,
      label: tab.label,
      active: true,
      link: tab.link,
      icon: tab.icon
    };
    dispatch(adminActions.addTab(newTab));
  };
  return (
    <Sider width={250} breakpoint="sm" collapsedWidth="0" style={{background: "#f5f5f5", paddingLeft: 8}}>
      <div className="logo-admin">
        <img src="/logo-text.png" alt="" />
      </div>

      <Menu
        // theme="dark"
        style={{ marginTop: 12, borderRadius: 8, maxHeight: "87vh", overflow: "auto", boxShadow: "1px 1px 5px #eee" }}
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={adminNavigationItems.map(
          ({ key, icon, label, link, children }) => {
            return children
              ? {
                key,
                icon,
                label,
                children: children.map(({key, icon, label, link}) => {
                  return {
                    key,
                    icon,
                    label: (
                      <Link
                        onClick={() => handleAddTab({ key, label, link, icon })}
                        to={link}
                      >
                        {label}
                      </Link>
                    ),
                  }
                })
              }
              : {
                  key,
                  icon,
                  label: (
                    <Link
                      onClick={() => handleAddTab({ key, label, link, icon })}
                      to={link}
                    >
                      {label}
                    </Link>
                  ),
                };
          }
        )}
      />
    </Sider>
  );
}
