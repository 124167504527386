import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './reducers/adminReducer';
import gestionProduitsReducer from "./reducers/gestionProduitsReducer";
import gammeReducer from "./reducers/gammeReducer";
import categorieReducer from "./reducers/categorieReducer";
import sousCategorieReducer from "./reducers/sousCategoriesReducer";
import produitReducer from './reducers/produitReducer';
import userReducer from './reducers/userReducer';
import venteReducer from './reducers/venteReducer';
import fraisTaxesReducer from './reducers/fraisTaxesReducer';
import transporteurReducer from './reducers/transporteurReducer';


const store = configureStore({
    reducer: {
        admin: adminReducer,
        gestionProduits: gestionProduitsReducer,
        gamme: gammeReducer,
        categorie: categorieReducer,
        sousCategorie: sousCategorieReducer,
        produit: produitReducer,
        user: userReducer,
        vente: venteReducer,
        fraisTaxes: fraisTaxesReducer,
        transporteur: transporteurReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
});

export default store;
