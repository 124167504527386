import {Outlet} from "react-router-dom";
import {Content} from "antd/es/layout/layout";
import {Layout} from "antd";
import PageTabButton from "../components/pageTabButton";
import {useSelector} from "react-redux";
import {adminStateSelector} from "../../store/reducers/adminReducer";

export default function Main({colorBgContainer}){
    const {tabs} = useSelector(adminStateSelector);
    return (
       <Layout>
           <div id={"page-navigator"}>
               {
                   tabs
                       .map(t => (
                           <PageTabButton key={t.id} tab={t}/>
                       ))
               }
           </div>
           <Content
               style={{
                   margin: '0 16px 24px 16px',
                   padding: 24,
                   minHeight: 280,
                   background: colorBgContainer,
                   overflow: "auto",
                   borderRadius: 8,
                   boxShadow: ".5rem .5rem 12px #eee"
               }}
           >
               <Outlet/>
           </Content>
       </Layout>


    )
}
