import {createAsyncThunk} from "@reduxjs/toolkit";

const genericThunk = (apiClass, extraThunkFunctions = {}) => {
    const getAll = createAsyncThunk(`${apiClass.resource}/getAll`, async () => {
        return await apiClass.getAll();
    });

    const get = createAsyncThunk(`${apiClass.resource}/get`, async (id) => {
        return await apiClass.get(id);
    });

    const create = createAsyncThunk(`${apiClass.resource}/create`, async (data) => {
        return await apiClass.create(data);
    });

    const update = createAsyncThunk(`${apiClass.resource}/update`, async ({id, data}, thunkAPI) => {
        try {
            return await apiClass.update(id, data);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.error)
        }
    });

    const remove = createAsyncThunk(`${apiClass.resource}/remove`, async (id, thunkAPI) => {
        try {
            return await apiClass.delete(id);
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    });

    return { getAll, get, create, update, remove, ...extraThunkFunctions };
};

export default genericThunk;
