import { createSlice } from "@reduxjs/toolkit";
import categorieThunk from "../thunks/categorieThunk";
const initialState = {
  rows: [],
  status: "idle",
  createStatus: "idle",
  updateStatus: "idle",
  deleteStatus: "idle",
  error: null,
  formMode: null,
  actionFulfilled: false,
  images: [],
  form: {
    id: undefined,
    id_gamme: null,
    label: "",
    image: "",
  },
};
const slice = createSlice({
  name: "categorie",
  initialState,
  reducers: {
    setRows: (state, action) => {
      state.rows = action.payload;
    },
    setCategorieState: (state, {payload}) => {
      return {...state, ...payload}
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(categorieThunk.getAll.pending, (state) => {
        state.status = "loading";
      })
      .addCase(categorieThunk.getAll.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rows = action.payload;
      })
      .addCase(categorieThunk.getAll.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(categorieThunk.create.pending, (state) => {
        state.createStatus = "loading";
        state.actionFulfilled = false
      })
      .addCase(categorieThunk.create.fulfilled, (state, action) => {
        state.createStatus = "succeeded";
        state.actionFulfilled = true
        state.rows.push(action.payload);
        state.form = initialState.form;
      })
      .addCase(categorieThunk.create.rejected, (state, action) => {
        state.createStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(categorieThunk.update.pending, (state) => {
        state.updateStatus = "loading";
        state.actionFulfilled = false
      })
      .addCase(categorieThunk.update.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.actionFulfilled = true
        const index = state.rows.findIndex((row) => {
          return row.id === action.payload.id;
        });
        if (index !== -1) {
          state.rows[index] = action.payload;
        }
      })
      .addCase(categorieThunk.update.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.error = action.payload;
      })

      .addCase(categorieThunk.remove.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(categorieThunk.remove.fulfilled, (state, action) => {
        //     state.deleteStatus = "succeeded";
        state.rows = state.rows.filter(
          (rows) => rows.id !== Number(action.payload.id)
        );
      })
      .addCase(categorieThunk.remove.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(categorieThunk.get.pending, (state) => {
        state.status = "loading";
      })
      .addCase(categorieThunk.get.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.rows.findIndex(
          (rows) => rows.id === action.payload.id
        );
        if (index !== -1) {
          state.rows[index] = action.payload;
        }
      })
      .addCase(categorieThunk.get.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setrows, setCategorieState } = slice.actions;
export const categorieStateSelector = (state) => state.categorie;
export default slice.reducer;
