import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProduitsApi from "../../../../api/produitApi";
import { IMG_URL, poidsTableColumns } from "../../../../config/constants";
import {
  closeProduitForm,
  produitStateSelector,
  setProduitFilterForm,
  setProduitOldValues,
  setProduitState,
} from "../../../../store/reducers/produitReducer";
import produitThunk from "../../../../store/thunks/produitThunk";
import AppTable from "../../../components/AppTable";
import EditableTableCell from "../../../components/EditableTableCell";
import FilesUploader from "../../../components/filesUploader";
import ErrorModal from "../../../components/errorModal";
const api = new ProduitsApi();
export default function GestionProduits() {
  const {
    rows,
    gammes,
    categories,
    sousCategories,
    taxes,
    form,
    filterForm,
    old_categorie,
    old_reference,
    formMode,
    prix_ttc,
    prix_ht_client,
    prix_ht_ambassadeur,
    error,
    poidsRows,
    columnsPoids,
    categoriesGamme,
    sousCategoriesCategorie,
    id_gamme,
    images
  } = useSelector(produitStateSelector);

  
  const setFormMode = (value) => {
    dispatch(setProduitState({ field: "formMode", value }));
  };
  const [formRef] = Form.useForm();

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 40,
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (_, row) =>
        row.images.length > 0 ? (
          <Avatar
            src={`${IMG_URL}${row.images.find((i) => i.par_defaut).image}`}
            alt=""
          />
        ) : null,
      width: 80,
    },
    {
      title: "Libéllé",
      dataIndex: "label",
      width: 250,
      render: (_, row) => (
        <EditableTableCell
          initialValue={row.label}
          onEnter={(value) =>
            submitEditableCell(row.id, { ...row, label: value }, row.images)
          }
        />
      ),
    },
    {
      title: "Activé",
      dataIndex: "actif",
      render: (_, row) => (
        <Checkbox
          checked={row.actif}
          onChange={(e) =>
            dispatch(
              produitThunk.update({
                id: row.id,
                data: { ...row, actif: !row.actif },
              })
            )
          }
        />
      ),
      width: 50,
    },
    {
      title: "Référence",
      dataIndex: "reference",
      width: 100,
    },
    {
      title: "Description",
      dataIndex: "description",
      ellipsis: true,
      width: 180,
      render: (_, row) => (
        <EditableTableCell
          initialValue={row.description}
          onEnter={(value) =>
            submitEditableCell(
              row.id,
              { ...row, description: value },
              row.images
            )
          }
        />
      ),
    },
    {
      title: "Prix_ht",
      dataIndex: "prix_ht",
      width: 80,
      render: (_, row) => (
        <EditableTableCell
          initialValue={Number(row.prix_ht).toFixed(3)}
          onEnter={(value) =>
            submitEditableCell(row.id, { ...row, prix_ht: value }, row.images)
          }
        />
      ),
    },
    {
      title: "Max vente",
      dataIndex: "max_vente",
      width: 80,
      render: (_, row) => (
        <EditableTableCell
          initialValue={row.max_vente}
          onEnter={(value) =>
            submitEditableCell(row.id, { ...row, max_vente: value }, row.images)
          }
        />
      ),
    },
    {
      title: "Poids",
      dataIndex: "poids",
      width: 80,
      render: (_, row) => (
        <EditableTableCell
          initialValue={row.poids}
          onEnter={(value) =>
            submitEditableCell(row.id, { ...row, poids: value }, row.images)
          }
        />
      ),
    },
    {
      title: "Gamme",
      dataIndex: "label_gamme",
      width: 100,
    },
    {
      title: "Catégorie",
      dataIndex: "label_categorie",
      width: 100,
    },
    {
      title: "Sous catégorie",
      dataIndex: "label_sous_categorie",
      width: 100,
    },
    {
      title: "Actions",
      fixed: "right",
      render: (_, row) => (
        <Space>
          <Button
            onClick={() => onUpdateClicked(row)}
            type="link"
            size="small"
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => duplicateProduit(row)}
            type="link"
            size="small"
            icon={<CopyOutlined />}
          />
          <Popconfirm
            title="Confirmation de suppression"
            description="Vous êtes surs de vouloir supprimer cet entité?"
            onConfirm={() => deleteEntity(row.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
      width: 100,
    },
  ];

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeProduitForm())
    formRef.setFieldsValue(form);
  };

  const setImages = value => {
    dispatch(setProduitState({field: "images", value}))
  }


  const handleChangeFilterForm = (e) => {
    const { name: field, value } = e.target ?? e;
    dispatch(setProduitFilterForm({ field, value }));
  };

  const submitFilterForm = (e) => {
    e.preventDefault();
    dispatch(produitThunk.getFilteredProduitsList(filterForm));
  };

  const submitForm = (values) => {
    if (values.id === undefined)
      dispatch(
        produitThunk.create({
          ...values,
          images: images.map((image) => {
            return { image: image.name };
          }),
        })
      );
    else
      dispatch(
        produitThunk.update({
          id: values.id,
          data: {
            ...values,
            images: images.map((image) => {
              return { image: image.name };
            }),
          },
        })
      );
    onClose();
  };

  const submitEditableCell = (id, values, images) => {
    dispatch(
      produitThunk.update({
        id,
        data: {
          ...values,
          images: images.map((image) => {
            return { image: image.image };
          }),
        },
      })
    );
  };

  const onUpdateClicked = (row) => {
    dispatch(produitThunk.prepareProduitForUpdateOrDuplicate({id: row.id, action: "update", formRef}))
  };

  const duplicateProduit = (row) => {
    dispatch(produitThunk.prepareProduitForUpdateOrDuplicate({id: row.id, action: "create", formRef}))
  };

  const deleteEntity = (id) => {
    dispatch(produitThunk.remove(id));
  };

  const handleChangeCategorie = (id) => {
    dispatch(
      produitThunk.handleChangeProduitPoidsEntities({
        field: "id_categorie",
        value: id,
        formRef,
      })
    );
  };



  const { label, reference, gamme, categorie, sousCategorie } = filterForm;
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between my-2">
        <Typography.Title className="m-0" level={4}>
          Gestion des produits
        </Typography.Title>
        <Tooltip title="Ajouter produit">
          <Button
            onClick={() => setFormMode("create")}
            type="primary"
            size={"small"}
            icon={<PlusOutlined />}
          >
            Ajouter
          </Button>
        </Tooltip>
      </div>
      <form onSubmit={submitFilterForm}>
        <div className="row filter-form mt-3 mb-2 gy-2">
          <div className="col-12 col-md-2">
            <Form.Item>
              <Input
                name="label"
                value={label}
                onChange={handleChangeFilterForm}
                placeholder="Libéllé"
              />
            </Form.Item>
          </div>
          <div className="col-12 col-md-2">
            <Form.Item>
              <Input
                name="reference"
                value={reference}
                onChange={handleChangeFilterForm}
                placeholder="Référence"
              />
            </Form.Item>
          </div>

          <div className="col-12 col-md-2">
            <Form.Item>
              <Select
                value={gamme}
                onChange={(value) => {
                  formRef.setFieldValue({ field: "id_categorie", value: null });
                  handleChangeFilterForm({ name: "gamme", value });
                }}
                placeholder="Gamme"
              >
                <Select.Option value="">Toutes les Gamme</Select.Option>
                {gammes.map((g) => (
                  <Select.Option key={g.id} value={g.id}>
                    {g.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-12 col-md-2">
            <Form.Item>
              <Select
                value={categorie}
                onChange={(value) =>
                  handleChangeFilterForm({ name: "categorie", value })
                }
                placeholder="Catégorie"
              >
                <Select.Option value="">Toutes les Catégorie</Select.Option>
                {categories.map((c) => (
                  <Select.Option key={c.id} value={c.id}>
                    {c.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-12 col-md-2">
            <Form.Item>
              <Select
                value={sousCategorie}
                onChange={(value) =>
                  handleChangeFilterForm({ name: "sousCategorie", value })
                }
                placeholder="Sous Catégorie"
              >
                <Select.Option value="">
                  Toutes les Sous Catégorie
                </Select.Option>
                {sousCategories.map((sc) => (
                  <Select.Option key={sc.id} value={sc.id}>
                    {sc.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-12 col-md-2">
            <Button
              className="w-100"
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
            >
              Recherche
            </Button>
          </div>
        </div>
      </form>
      <AppTable
        columns={columns}
        size="small"
        bordered
        dataSource={rows}
        scroll={{ x: 1100 }}
        pagination={{ pageSize: 20 }}
      />
      <Drawer
        title={
          formMode === "create"
            ? "Créer un nouveau produit"
            : "Modifier le produit"
        }
        width={"100%"}
        onClose={onClose}
        open={formMode !== null}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          initialValues={form}
          form={formRef}
          onFinish={submitForm}
          layout="vertical"
        >
          <Row gutter={16}>
            <FormItem name="id" hidden>
              <Input />
            </FormItem>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name={"label"}
                label={"Libéllé"}
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir le label du produit",
                  },
                ]}
              >
                <Input placeholder="label" id="input-label" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={1}>
              <Form.Item label="Activé" name="actif" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={3}>
              <Form.Item label="Gamme" name="id_gamme">
                <Select
                  onChange={(value) => {
                    formRef.setFieldValue("id_categorie", "");
                    formRef.setFieldValue("id_sous_categorie", "");
                    dispatch(
                      setProduitState({
                        field: "categoriesGamme",
                        value: categories.filter((c) => c.id_gamme === value),
                      })
                    )
                    
                  }}
                  placeholder="Gamme"
                  allowClear
                >
                  <Select.Option value=""></Select.Option>
                  {gammes.map((g) => (
                    <Select.Option key={g.id} value={g.id}>
                      {g.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={3}>
              <Form.Item
                name="id_categorie"
                label="Catégorie"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(value) => handleChangeCategorie(value)}
                  placeholder="catégorie"
                  allowClear
                >
                  <Select.Option value={""}></Select.Option>
                  {categoriesGamme.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={3}>
              <Form.Item name="id_sous_categorie" label="Sous catégorie">
                <Select placeholder="sous catégorie" allowClear>
                  <Select.Option value={""}></Select.Option>
                  {sousCategoriesCategorie.map((c) => (
                    <Select.Option key={c.id} value={c.id}>
                      {c.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={2}>
              <Form.Item name={"reference"} label={"Référence"}>
                <Input readOnly placeholder="Référence" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={2}>
              <Form.Item
                label="Max vente"
                name="max_vente"
                rules={[{ required: true }]}
              >
                <Input placeholder="max_vente" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={2}>
              <Form.Item
                label="Poids (g)"
                name="poids"
                rules={[{ required: true }]}
              >
                <Input placeholder="poids" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Description" name="description">
                <Input.TextArea rows={3} placeholder="Description" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={3}>
              <Form.Item label="Prix TTC">
                <Input
                  placeholder="0"
                  value={prix_ttc}
                  onClick={(e) => e.target.select()}
                  onChange={(e) =>
                    dispatch(
                      produitThunk.handleChangeProduitPoidsEntities({
                        field: "prix_ttc",
                        value: e.target.value,
                        formRef,
                      })
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Form.Item name="prix_ht" hidden={true}>
              <Input />
            </Form.Item>
            <Col xs={12} sm={12} md={3}>
              <Form.Item label="HT Client">
                <Input value={prix_ht_client} />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={3}>
              <Form.Item label="HT Ambassadeur">
                <Input value={prix_ht_ambassadeur} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Typography.Title level={4}>Poids</Typography.Title>
              {columnsPoids.length > 0 && (
                <Table
                  bordered
                  scroll={{ x: 1000 }}
                  pagination={{ hideOnSinglePage: true }}
                  columns={columnsPoids}
                  dataSource={poidsRows}
                />
              )}
            </Col>

            <Col span={24}>
              <Typography.Title level={4}>Images</Typography.Title>
              {formMode !== null && (
                <FilesUploader
                  multiple
                  initalFiles={images}
                  onServerUploaded={(files) =>
                    setImages(
                      files.map((file) => {
                        return { name: file.name };
                      })
                    )
                  }
                  nbFiles={8}
                />
              )}
            </Col>
          </Row>

          <div className="d-flex justify-content-end align-items-center mt-3 border-top pt-2">
            <Button onClick={onClose} icon={<CloseOutlined />}>
              Annuler
            </Button>
            <Button
              //   loading={status === "loading"}
              htmlType={"submit"}
              type="primary"
              icon={<SaveOutlined />}
              className="ms-2"
            >
              Enregistrer
            </Button>
          </div>
        </Form>
      </Drawer>
      <ErrorModal
        close={() => dispatch(setProduitState({ field: "error", value: null }))}
        message={error}
        open={error !== null}
      />
    </div>
  );
}
