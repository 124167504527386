import dayjs from "dayjs";
export const dayjsToSQLDate = (date) => {
  return date?.format("YYYY-MM-DD HH:mm") ?? "";
};
export const SQLDateTodayjs = (date) => {
  return date ? dayjs(date) : "";
};
export const dateToFr = date => {
    return date ? dayjs(date).format("DD/MM/YYYY") : ""
}
export const dateTimeToFr = date => {
    return date ? dayjs(date).format("DD/MM/YYYY HH:mm") : ""
}