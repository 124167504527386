import { Typography } from 'antd';
const { Title } = Typography;
function PageTitle({value,icon = null, rightSide = null}) {
  return <div className="d-flex align-items-center justify-content-between">
    <Title className='mb-1' level={2}>{icon}{value}</Title>
    {rightSide}
  </div>;
}

export default PageTitle;
