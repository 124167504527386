// api/BaseApi.js
import axiosInstance, { axiosPost } from "../config/axiosInstance";
import BaseApi from "./baseApi";

export default class ProduitsApi extends BaseApi {
  constructor() {
    super("produits");
  }

  async getFilteredProduits(data) {
    return await axiosPost
      .post("/" + this.resource + "/filtered", data)
      .then((res) => res.data)
      .catch((error) => error);
  }
  async getFilteredProduitsList(data) {
    return await axiosPost
      .post("/" + this.resource + "/filtered-list", data)
      .then((res) => res.data)
      .catch((error) => error);
  }

  async getProduitPourVente(reference) {
    return await axiosInstance
      .get(`/${this.resource}/produit-pour-vente/${reference}`)
      .then((res) => res.data)
      .catch((error) => error);
  }

  async getAllReferences() {
    return await axiosInstance
      .get(`/${this.resource}/all-references`)
      .then((res) => res.data)
      .catch((error) => error);
  }

  async getMaxReferenceByCategorieId(id){
    return await axiosInstance
    .get(`/${this.resource}/max-reference/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  }


}
