import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabs: []
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        addTab: (state, action) => {
            state.tabs = state.tabs.map((tab) => {
                return {
                    ...tab,
                    active: false,
                };
            });
            const existingTab = state.tabs.find(tab => tab.id === action.payload.id);
            if (existingTab) {
                existingTab.active = true;
            } else {
                state.tabs.push({...action.payload, active: true});
            }
        },
        setActiveTab: (state, action) => {
            state.tabs = state.tabs.map((tab) => {
                if (tab.id === action.payload.id) {
                    return {
                        ...tab,
                        active: true,
                    };
                }
                return {
                    ...tab,
                    active: false,
                };
            });
        },
        deleteTab: (state, action) => {
            const index = state.tabs.findIndex(tab => tab.id === action.payload);
            if (index !== -1) {
                const isCurrentTab = state.tabs[index].active;
                state.tabs.splice(index, 1);
                if (state.tabs.length > 0) {
                    if (isCurrentTab) {
                        let tabWillActivate = state.tabs[index-1]
                        if(!tabWillActivate) tabWillActivate = state.tabs[index+1]
                        if(!tabWillActivate) tabWillActivate = state.tabs[index]
                        tabWillActivate.active = true;
                    }
                }
            }
        },
    },
});

export const adminActions = {
    addTab: adminSlice.actions.addTab,
    setActiveTab: adminSlice.actions.setActiveTab,
    deleteTab: adminSlice.actions.deleteTab,
};
export const adminStateSelector = state => state.admin
export default adminSlice.reducer;
