import { createSlice } from "@reduxjs/toolkit";
import transporteurThunk from "../thunks/transporteurThunk";
const FORM_IS = { label: "" };
const initialState = {
  form: FORM_IS,
  rows: [],
  error: false,
  loading: false,
  formMode: null,
};

const slice = createSlice({
  name: "transporteur",
  initialState,
  reducers: {
    setTransporteurState: (state, { payload }) => {
      const { field, value } = payload;
      state[field] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(transporteurThunk.getAll.fulfilled, (state, { payload }) => {
        state.rows = payload;
      })
      .addCase(transporteurThunk.create.fulfilled, (state, { payload }) => {
        state.rows.push(payload);
        state.formMode = null;
      })
      .addCase(transporteurThunk.update.fulfilled, (state, { payload }) => {
        const index = state.rows.findIndex((r) => r.id === payload.id);
        state.rows[index] = payload;
        state.formMode = null;
      })
      .addCase(transporteurThunk.remove.fulfilled, (state, { payload }) => {
        state.rows = state.rows.filter((r) => r.id !== Number(payload.id));
      })

      .addMatcher(
        (action) => action.type.endsWith("/pending"), // Match any action with a "pending" suffix
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, { payload }) => {
          state.error = payload;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.error = false;
          state.loading = false;
        }
      );
  },
});

export const { setTransporteurState } = slice.actions;
export const transporteurStateSelector = (state) => state.transporteur;
export default slice.reducer;
