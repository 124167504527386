import { createSlice } from "@reduxjs/toolkit";
import produitThunk, { default as thunk } from "../thunks/produitThunk";
import { STATE_STATUS } from "../../config/constants";
import {
  prepareImagesProduitForFileUploader,
  preparePoidsRowsAndColumns,
} from "../../utils/produits_utils";
const initialState = {
  rows: [],
  gammes: [],
  id_gamme: "",
  categories: [],
  sousCategories: [],
  categoriesGamme: [],
  sousCategoriesCategorie: [],
  taxes: [],
  status: STATE_STATUS.idle,
  poidsRows: [],
  columnsPoids: [],
  prix_ttc: 0,
  prix_ht_client: 0,
  prix_ht_ambassadeur: 0,
  poids: null,
  baseCalcul: null,
  error: null,
  formMode: null,
  form: {
    id: undefined,
    id_gamme: "",
    id_categorie: null,
    reference: "",
    id_sous_categorie: null,
    label: "",
    image: "",
    description: "",
    prix_ht: "",
    max_vente: "",
    poids: "",
    actif: 1,
  },
  filterForm: {
    label: "",
    reference: "",
    gamme: "",
    categorie: "",
    sousCategorie: "",
  },
  old_reference: "",
  old_categorie: "",
  images: [],
};

const slice = createSlice({
  name: "produit",
  initialState,
  reducers: {
    setProduitFilterForm: (state, { payload }) => {
      const { field, value } = payload;
      state.filterForm[field] = value;
    },
    setProduitOldValues: (state, { payload }) => {
      const { reference, id_categorie } = payload;
      state.old_reference = reference;
      state.old_categorie = id_categorie;
    },
    setProduitState: (state, { payload }) => {
      state[payload.field] = payload.value;
    },
    closeProduitForm: (state) => {
      const { gammes, categories, sousCategories } = state;
      return {
        ...initialState,
        filterForm: state.filterForm,
        rows: state.rows,
        gammes,
        categories,
        sousCategories,
        taxes: state.taxes,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.getAll.fulfilled, (state, { payload }) => {
        const { produits, produitsData } = payload;
        const { gammes, categories, sousCategories, taxes } = produitsData;
        state.rows = produits;
        state.gammes = gammes;
        state.categories = categories;
        state.sousCategories = sousCategories;
        state.taxes = taxes;
        state.status = STATE_STATUS.idle;
      })
      .addCase(thunk.create.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rows.push(action.payload);
        state.form = initialState.form;
      })
      .addCase(thunk.update.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.rows.findIndex(
          (rows) => rows.id === action.payload.id
        );
        if (index !== -1) {
          state.rows[index] = action.payload;
        }
      })
      .addCase(thunk.remove.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.rows = state.rows.filter(
          (rows) => rows.id !== Number(action.payload)
        );
      })
      .addCase(thunk.get.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.rows.findIndex(
          (rows) => rows.id === action.payload.id
        );
        if (index !== -1) {
          state.rows[index] = action.payload;
        }
      })
      .addCase(
        thunk.getFilteredProduitsList.fulfilled,
        (state, { payload }) => {
          const { produits, produitsData } = payload;
          const { gammes, categories, sousCategories, taxes } = produitsData;
          state.rows = produits;
          state.gammes = gammes;
          state.categories = categories;
          state.sousCategories = sousCategories;
          state.taxes = taxes;
          state.status = STATE_STATUS.idle;
        }
      )
      .addCase(
        produitThunk.prepareProduitForUpdateOrDuplicate.fulfilled,
        (state, { payload: { produit, categorieData, action } }) => {
          if (action === "update") {
            state.old_categorie = produit.id_categorie;
            state.old_reference = produit.reference;
          }
          state.categoriesGamme = state.categories.filter(
            (c) => c.id_gamme === produit.id_gamme
          );
          state.sousCategoriesCategorie = state.sousCategories.filter(
            (sc) => sc.id_categorie === produit.id_categorie
          );
          state.poids = categorieData.poids;
          state.baseCalcul = categorieData.baseCalcul;
          state.prix_ttc = state.taxes
            .reduce(
              (total, c) => total * (1 + Number(c.valeur) / 100),
              produit.prix_ht
            )
            .toFixed(3);
          state.prix_ht_client = state.taxes
            .filter((t) => t.client_assujetti)
            .reduce(
              (total, c) => total / (1 + Number(c.valeur) / 100),
              Number(state.prix_ttc)
            )
            .toFixed(3);
          state.prix_ht_ambassadeur = state.taxes
            .filter((t) => t.ambassadeur_assujetti)
            .reduce(
              (total, c) => total / (1 + Number(c.valeur) / 100),
              Number(state.prix_ttc)
            )
            .toFixed(3);
          const { poidsRows, columnsPoids } = preparePoidsRowsAndColumns(
            state.poids,
            state.baseCalcul,
            state.prix_ht_ambassadeur,
            state.prix_ttc
          );
          state.poidsRows = poidsRows;
          state.columnsPoids = columnsPoids;
          if (action === "update")
            state.images = prepareImagesProduitForFileUploader(produit.images);
          state.formMode = action;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/pending"), // Match any action with a "pending" suffix
        (state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );
  },
});

export const {
  setProduitFilterForm,
  setProduitOldValues,
  setProduitState,
  closeProduitForm,
} = slice.actions;
export const produitStateSelector = (state) => state.produit;
export default slice.reducer;
